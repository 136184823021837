import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavitemsService } from '../../services/navitems.service';
import { JwtService } from '../../services/jwt.service';
import { StorageService as storage } from '../../services/storage.service';
import { SidenaItems } from '../../interfaces/sidena-items';
import { SidenavUserdata } from '../../interfaces/sidenav-userdata';

@Component({
  selector: 'app-navitems',
  templateUrl: './navitems.component.html',
  styleUrls: ['./navitems.component.scss']
})
export class NavitemsComponent implements OnInit {
  @Input() sidenav;
  menuData: SidenaItems[];
  userData: SidenavUserdata;

  constructor(private navItemsService: NavitemsService,
    private jwtService: JwtService,
    private router: Router
  ) { }

  ngOnInit() {
    this.navItemsService.routerItems.subscribe(m => this.menuData = m);
    this.navItemsService.userData.subscribe(d => this.userData = d);
  }

  /**
   * Side Close (click menu options)
   * @param logout Function Logou
   */
  sideClose(logout: Function) {
    if (logout) {
      logout();
    }
    this.sidenav.close();
  }

  trackByFn(index, item) {
    return index;
  }
}
