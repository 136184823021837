import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Product } from '../navigate-and-discover.component';
import * as Highcharts from 'highcharts';
import * as Moment from 'moment';
import { DialogSelectRangeComponent } from '../dialog-select-range/dialog-select-range.component';
import { MatDialog } from '@angular/material/dialog';
import { IntervalSelected } from '../../chart-interval-type-selector/chart-interval-type-selector.component';


export interface Range {
  beginDate;
  endDate;
}

@Component({
  selector: 'app-fund-performance',
  templateUrl: './fund-performance.component.html',
  styleUrls: ['./fund-performance.component.css']
})
export class FundPerformanceComponent implements OnInit {

  benchmark: Product;
  cdi: Product;
  ipca: Product;
  ibov: Product;
  poup: Product;

  update = false;

  @Input() products: Product[];
  @Output() benchmarkChange =  new EventEmitter<Product>();

  range: Range;

  compareChart: Highcharts.Chart;
  Highcharts = Highcharts;
  chartData: Object;

  constructor(private cdr: ChangeDetectorRef) { }
  intervalType = 'GREAT';

  ngOnInit(): void {
    this.cdi = {color: '#cccccc', name: 'CDI', absolute_profitability: 11.96,
    relative_profitability: 100.00, volatility: 0.05, sharpe: null};
    this.ipca = {color: '#cccccc', name: 'IPCA', absolute_profitability: 12.96,
    relative_profitability: 100.00, volatility: 0.05, sharpe: null};
    this.ibov = {color: '#cccccc', name: 'BOV', absolute_profitability: 13.96,
    relative_profitability: 100.00, volatility: 0.05, sharpe: null};
    this.poup = {color: '#cccccc', name: 'POUP', absolute_profitability: 14.96,
    relative_profitability: 100.00, volatility: 0.05, sharpe: null};
    this.intervalType = 'GREAT';
    this.setBenchmark(this.cdi);
    this.chartData = this.buildCompareChart();
  }

  setBenchmark(benchmark: Product) {
    this.benchmark = benchmark;
    this.benchmarkChange.emit(this.benchmark);
    this.addSeriesToCompareChart();
  }

  setChartIntervalType(event: IntervalSelected) {
    this.intervalType = event.type;
    this.range = event.range;

    this.addSeriesToCompareChart();
  }

  buildInterval() {
    let interval = [];
    switch (this.intervalType) {
      case 'GREAT':
        interval = [];
        for (let i = 2010; i <= 2017; i++) {
          interval.push(i);
        }
      break;
      case 'ONE_YEAR':
        interval = [];
        for ( let i = 11; i >= 0; i--) {
          interval.push(this.addRealMonth(Moment(), -i).format('YYYY-MM'));
        }
      break;
      case 'ON_YEAR':
        interval = [];
        const date = Moment();


        for (let i = new Date().getUTCMonth(); i >= 0; i--) {
          interval.push(this.addRealMonth(Moment(), -i).format('YYYY-MM'));
        }
      break;
      case 'TWO_YEARS':
        interval = [];
        for (let i = 23; i >= 0; i--) {
          interval.push(this.addRealMonth(Moment(), -i).format('YYYY-MM'));
        }
      break;
      case 'RANGE':
        interval = [];

        const days = this.range.endDate.diff(this.range.beginDate, 'days');

        for (let i = 0; i <= days; i++) {
          interval.push(Moment(this.range.beginDate).add(i, 'days').format('YYYY-MM-DD'));
        }
      break;
    }

    return interval;

  }

  addRealMonth(d, nMonths) {
    const fm = Moment(d).add(nMonths, 'M');
    const fmEnd = Moment(fm).endOf('month');
    return d.date() !== fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm;
  }

  setCompareChartInstance(chart) {
    this.compareChart = chart;
  }

  addSeriesToCompareChart() {
    const options = this.buildCompareChart();
    this.products.forEach(p => {
      options.series.push(
        this.getProductData(p)
      );
    });

    this.chartData = options;

  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getProductData(p: Product) {
    const data = [];
    for (let i = 1; i <= this.buildInterval().length; i++) {
      data.push(this.getRandomInt(100000));
    }
    return {
      name: p.name,
      data: data,
      color: p.color };
  }

  chartBenchmarkSelected(op: string) {
    return this.benchmark.name === op;
  }

  updateChart() {
    this.update = true;
    this.cdr.detectChanges();
    this.update = false;
  }

  buildCompareChart() {
    const benchmarkData = this.getProductData(this.benchmark).data;

    const chart  = {
      title: {
        text: ''
    },

    subtitle: {
        text: ''
    },
    xAxis: {
      categories: this.buildInterval()
    },

    yAxis: {
        title: {
            text: ''
        }
      },


    series: [{
      name: this.benchmark.name,
        data: benchmarkData,
        color: this.benchmark.color
    }]


    };
    return chart;
  }


}
