import { Injectable, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  @Output() appVersion: EventEmitter<string> = new EventEmitter();

  constructor() { }
}
