import { Injectable, Output, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from '../interfaces/breadcrumb';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  @Output() breadcrumb: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject(null);
  @Output() breadcrumbSubMenu: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject(null);

  constructor() { }
}
