import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // Generic JWT
  static saveJwt(jwt: string): boolean {
    localStorage.setItem('jwt', jwt);
    return true;
  }

  static getJwt(): string {
    const jwt: string = localStorage.getItem('jwt');
    return jwt;
  }

  static deleteJwt(): boolean {
    localStorage.removeItem('jwt');
    return true;
  }

  // PARTNER JWT
  static savePartnerJwt(jwt: string): boolean {
    localStorage.setItem('partner_jwt', jwt);
    return true;
  }

  static getPartnerJwt(): string {
    const jwt: string = localStorage.getItem('partner_jwt');
    return jwt;
  }

  static deletePartnerJwt(): boolean {
    localStorage.removeItem('partner_jwt');
    return true;
  }

  // INVESTOR JWT
  static saveInvestorJwt(jwt: string): boolean {
    localStorage.setItem('investor_jwt', jwt);
    return true;
  }

  static getInvestorJwt(): string {
    const jwt: string = localStorage.getItem('investor_jwt');
    return jwt;
  }

  static deleteInvestorJwt(): boolean {
    localStorage.removeItem('investor_jwt');
    return true;
  }

  // MANAGER JWT
  static saveManagerJwt(jwt: string): boolean {
    localStorage.setItem('manager_jwt', jwt);
    return true;
  }

  static getManagerJwt(): string {
    const jwt: string = localStorage.getItem('manager_jwt');
    return jwt;
  }

  static deleteManagerJwt(): boolean {
    localStorage.removeItem('manager_jwt');
    return true;
  }

  // MANAGER SYSTEM
  static saveSystemJwt(jwt: string): boolean {
    localStorage.setItem('admin_jwt', jwt);
    return true;
  }

  static getSystemJwt(): string {
    const jwt: string = localStorage.getItem('admin_jwt');
    return jwt;
  }

  static deleteSystemJwt(): boolean {
    localStorage.removeItem('admin_jwt');
    return true;
  }

  // Storage Tools
  static save(key: string, value: any): boolean {
    localStorage.setItem(key, value);
    return true;
  }

  static get(key: string): string {
    const value: string = localStorage.getItem(key);
    return value;
  }

  static delete(key: string): boolean {
    localStorage.removeItem(key);
    return true;
  }

  static clear() {
    localStorage.clear();
  }

  constructor() { }
}


