import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  @Output() headerTitle: EventEmitter<string> = new EventEmitter();
  @Output() partnerName: EventEmitter<string> = new EventEmitter();
  @Output() moduleName: EventEmitter<string> = new EventEmitter();
  @Output() applyBg: EventEmitter<boolean> = new EventEmitter();
  @Output() language: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Output() theme: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Output() logo: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
  }
}
