
<h1 mat-dialog-title>{{'label.choose-product' | translate}}</h1>

<div mat-dialog-content>
    <div>
        <div *ngFor="let c of colors" [style.background]="c" (click)="setColor(c)" class="color-sample"></div>
        <mat-icon [title]="'label.choose-color' | translate" [(colorPicker)]="color" class="color-sample">palette</mat-icon>
    </div>
    <div *ngIf="color" class="color-sample" [ngStyle]="{'background-color': color, 'border-radius': '50%'}"> </div>
    <app-select-product [productControl]="productControl"></app-select-product>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <button mat-button (click)="onNoClick()">{{'btn.CANCEL' | translate}}</button>
    <button [disabled]="!productControl.value" mat-button [mat-dialog-close]="{product:productControl.value, color:color}" cdkFocusInitial>{{'btn.add-product' | translate}}</button>    
</div>
