import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UtilService } from '../../shared/services/util.service';
import { UpdateService } from '../../shared/services/update.service';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(private http: HttpClient,
    private updateService: UpdateService,
    private utilService: UtilService
  ) { }

  // Handle Error
  private handleError(error: Response | any): Promise<any> {
    // TODO LOG ERRORS
    return Promise.reject(error);
  }

 /**
 * Check App Update
 * @param res Response
 */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.updateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  // Get JWT Info
  getJwtInfo(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/jwt', { jwt: jwt }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  // Get JWT Info
  revokeJwt(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/jwt', { jwt: jwt }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Get IP Client
   */
  getIpClient(): Promise<any> {
    const url = this.utilService.makeUri(['/client-ip']);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }
}
