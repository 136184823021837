<div fxAlign="row">
  <app-select-product [productControl]="productControl"></app-select-product>
</div>
<mat-tab-group>
  <div *ngIf="!updating">
    
    <mat-tab  [label]="'label.general' | translate">
      <mat-card>
        <h1>{{productControl.value.name}}</h1>
        <div class="product-data" fxLayout="row">
          <div fxFlex>
            <div><strong>{{productData.patrimony | currency:'BRL'}}</strong></div>
            <div>{{'label.patrimony' | translate}}</div>
          </div>
          <div fxFlex>
            <div><strong>{{productData.shareHolders}}</strong></div>
            <div>{{'label.share-holders' | translate}}</div>
          </div>
          <div fxFlex>
            <div><strong>{{productData.funds}}</strong></div>
            <div>{{'label.funds' | translate}}</div>
          </div>
        </div>
      </mat-card>
      <div fxLayout="row" fxLayoutGap="10px" class="product-data-demonstration-wrapper">
        <div fxFlex="50%">
          <highcharts-chart [Highcharts]="Highcharts" [options]="patrimonyChartData" style="width:100%; height: 400px; display: block;">
          </highcharts-chart>
        </div>
        <div fxFlex="50%">
          <highcharts-chart [Highcharts]="Highcharts" [options]="patrimonyPieChartData" style="width:100%; height: 400px; display: block;">
          </highcharts-chart>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="product-data-demonstration-wrapper">
        <div fxFlex="50%">
          <highcharts-chart [Highcharts]="Highcharts" [options]="shareHoldersChartData" style="width: 100%; height: 400px; display: block;">
          </highcharts-chart>
        </div>
        <div fxFlex="50%">
          <highcharts-chart [Highcharts]="Highcharts" [options]="shareHoldersPieChartData" style="width: 100%; height: 400px; display: block;">
          </highcharts-chart>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" class="product-data-demonstration-wrapper">
        <div fxFlex="50%">
          <highcharts-chart [Highcharts]="Highcharts" [options]="fundsChartData" style="width: 100%; height: 400px; display: block;">
          </highcharts-chart>
        </div>
        <div fxFlex="50%">
          <highcharts-chart [Highcharts]="Highcharts" [options]="fundsPieChartData" style="width: 100%; height: 400px; display: block;">
          </highcharts-chart>
        </div>
      </div>
      
    </mat-tab>
    <mat-tab [label]="'label.partners' | translate"></mat-tab>
    <mat-tab [label]="'label.responsible' | translate"></mat-tab>
    <mat-tab [label]="'label.contact' | translate"></mat-tab>
    <mat-tab [label]="'label.funds' | translate"></mat-tab>
  </div>
</mat-tab-group>