import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Product } from '../navigate-and-discover.component';

@Component({
    selector: 'app-dialog-add-product',
    templateUrl: './dialog-add-product.component.html' ,
    styleUrls: ['./dialog-add-product.component.css']
  })
  export class DialogAddProductComponent implements OnInit {
    [x: string]: any;

    colors: string[] = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#00ffff', '#55a267', '#fc43a5'];
    color = '#ff0000';
    productControl = new FormControl();

    constructor(public dialogRef: MatDialogRef<DialogAddProductComponent>) {}

    ngOnInit() {
      this.color = '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    setColor(color: string) {
      this.color = color;
    }
}
