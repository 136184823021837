
<h1 mat-dialog-title>{{'label.choose-range' | translate}}</h1>

<div mat-dialog-content>
    <form [formGroup]="rangeForm">
    <mat-form-field>
        <mat-label>{{'label.begin_date'| translate}}</mat-label>
        <input matInput required formControlName="beginControl" [max]="rangeForm.value.endControl" [matDatepicker]="begin">
        <mat-datepicker-toggle matSuffix [for]="begin"></mat-datepicker-toggle>
        <mat-datepicker #begin></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'label.end_date'| translate}}</mat-label>
        <input matInput required formControlName="endControl" [min]="rangeForm.value.beginControl" [matDatepicker]="end">
        <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
        <mat-datepicker #end></mat-datepicker>
      </mat-form-field>
    </form>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <button mat-button (click)="onNoClick()">{{'btn.CANCEL' | translate}}</button>
    <button mat-button [disabled]="!rangeForm.valid" [mat-dialog-close]="rangeForm.value" cdkFocusInitial>{{'btn.SELECT' | translate}}</button>    
</div>
