import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

// HighCharts
import { HighchartsChartModule } from 'highcharts-angular';

// Text Mask
import { TextMaskModule } from 'angular2-text-mask';

// Pipe
import { SafePipe } from '../../pipe/safe.pipe';
import { CnpjPipe } from '../../pipe/cnpj.pipe';
import { FundTypeToKeyPipe } from '../../pipe/fund-type-to-key.pipe';

// Image Cropper
import { ImageCropperModule } from 'ngx-image-cropper';

// Color Picker
import { ColorPickerModule } from 'ngx-color-picker';

// Masl
import { NgxMaskModule, IConfig } from 'ngx-mask';
const maskConfig: Partial<IConfig> = {
  validation: false,
};

import { TableAssetsComponent } from '../../components/table-assets/table-assets.component';
import { AssetFormComponent } from '../../components/asset-form/asset-form.component';
import { DialogConfirmComponent } from '../../components/dialog-confirm/dialog-confirm.component';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { BreadcrumbSubmenuComponent } from '../../components/breadcrumb-submenu/breadcrumb-submenu.component';
import { RestrictionAssetsComponent } from '../../components/restriction-assets/restriction-assets.component';
import { RestrictionAssetGroupsComponent } from '../../components/restriction-asset-groups/restriction-asset-groups.component';
import { RestrictionLimitComponent } from '../../components/restriction-limit/restriction-limit.component';
import { ChartFutureValueComponent } from '../../components/chart-future-value/chart-future-value.component';
import { ChartCashFlowComponent } from '../../components/chart-cash-flow/chart-cash-flow.component';
import { ChartPieAllocationComponent } from '../../components/chart-pie-allocation/chart-pie-allocation.component';
import { ChartPieGoalIncomeComponent } from '../../components/chart-pie-goal-income/chart-pie-goal-income.component';
import { ChartFrontierComponent } from '../../components/chart-frontier/chart-frontier.component';

import {
  DialogNewRestrictionAssetsComponent
} from '../../components/dialog-new-restriction-assets/dialog-new-restriction-assets.component';

import {
  DialogNewRestrictionAssetClassComponent
} from '../../components/dialog-new-restriction-asset-class/dialog-new-restriction-asset-class.component';

import {
  DialogNewRestrictionLimitComponent
} from '../../components/dialog-new-restriction-limit/dialog-new-restriction-limit.component';

import { InvestorDataComponent } from '../../components/investor-data/investor-data.component';
import { InvestorAccountDataComponent } from '../../components/investor-account-data/investor-account-data.component';
import { InvestorPasswordComponent } from '../../components/investor-password/investor-password.component';
import { InvestorOrdersComponent } from '../../components/investor-orders/investor-orders.component';
import { DialogGoalComponent } from '../../components/dialog-goal/dialog-goal.component';
import { DialogIncomeComponent } from '../../components/dialog-income/dialog-income.component';
import { DialogOrderComponent } from '../../components/dialog-order/dialog-order.component';
import { PerformanceAnalysisComponent } from '../../components/performance-analysis/performance-analysis.component';
import { DialogClientFiltersComponent } from '../../components/dialog-client-filters/dialog-client-filters.component';
import { DialogClientGroupComponent } from '../../components/dialog-client-group/dialog-client-group.component';
import { TableInvestorAccountsComponent } from '../../components/table-investor-accounts/table-investor-accounts.component';
import { DialogPdiSettingComponent } from '../../components/dialog-pdi-setting/dialog-pdi-setting.component';
import { DialogGroupInvestorsComponent } from '../../components/dialog-group-investors/dialog-group-investors.component';
import { DialogSimpleEditInvestorComponent } from '../../components/dialog-simple-edit-investor/dialog-simple-edit-investor.component';
import { NavigateAndDiscoverComponent } from '../../components/funds/navigate-and-discover/navigate-and-discover.component';
import { PerformanceAnalyzeComponent } from '../../components/funds/performance-analyze/performance-analyze.component';
import { PerformanceSelectComponent } from '../../components/funds/performance-select/performance-select.component';
import { DialogAddProductComponent } from '../../components/funds/navigate-and-discover/dialog-add-product/dialog-add-product.component';
import { DialogSelectRangeComponent } from '../../components/funds/navigate-and-discover/dialog-select-range/dialog-select-range.component';
import { ListOfManagersComponent } from '../../components/funds/navigate-and-discover/list-of-managers/list-of-managers.component';
import * as selectProductComponent from '../../components/funds/navigate-and-discover/select-product/select-product.component';
import { FundPerformanceComponent } from '../../components/funds/navigate-and-discover/fund-performance/fund-performance.component';
import { FundDataExtraInfoComponent } from '../../components/funds/performance-analyze/fund-data-extra-info/fund-data-extra-info.component';
import { ChartIntervalTypeSelectorComponent } from '../../components/funds/chart-interval-type-selector/chart-interval-type-selector.component';
import { ComparisonMatrixComponent } from '../../components/funds/performance-analyze/comparison-matrix/comparison-matrix.component';
import { FundsComponent } from '../../components/funds/funds.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HighchartsChartModule,
    TextMaskModule,
    ImageCropperModule,
    ColorPickerModule,
    TranslateModule.forChild({}),
    NgxMaskModule.forRoot(maskConfig)
  ],
  declarations: [
    TableAssetsComponent,
    AssetFormComponent,
    DialogConfirmComponent,
    BreadcrumbComponent,
    BreadcrumbSubmenuComponent,
    RestrictionAssetsComponent,
    RestrictionAssetGroupsComponent,
    RestrictionLimitComponent,
    DialogNewRestrictionAssetsComponent,
    DialogNewRestrictionAssetClassComponent,
    DialogNewRestrictionLimitComponent,
    ChartFutureValueComponent,
    ChartCashFlowComponent,
    ChartPieAllocationComponent,
    ChartPieGoalIncomeComponent,
    ChartFrontierComponent,
    InvestorDataComponent,
    InvestorAccountDataComponent,
    InvestorPasswordComponent,
    InvestorOrdersComponent,
    DialogGoalComponent,
    DialogIncomeComponent,
    DialogOrderComponent,
    DialogPdiSettingComponent,
    PerformanceAnalysisComponent,
    SafePipe,
    CnpjPipe,
    FundTypeToKeyPipe,
    DialogClientFiltersComponent,
    DialogClientGroupComponent,
    DialogAddProductComponent,
    DialogSelectRangeComponent,
    TableInvestorAccountsComponent,
    DialogGroupInvestorsComponent,
    DialogSimpleEditInvestorComponent,
    NavigateAndDiscoverComponent,
    PerformanceAnalyzeComponent,
    PerformanceSelectComponent,
    ListOfManagersComponent,
    FundPerformanceComponent,
    selectProductComponent.SelectProductComponent,
    FundDataExtraInfoComponent,
    ChartIntervalTypeSelectorComponent,
    ComparisonMatrixComponent,
    FundsComponent
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    HighchartsChartModule,
    TextMaskModule,
    ImageCropperModule,
    ColorPickerModule,
    TranslateModule,
    TableAssetsComponent,
    AssetFormComponent,
    BreadcrumbComponent,
    DialogConfirmComponent,
    BreadcrumbSubmenuComponent,
    RestrictionAssetsComponent,
    RestrictionAssetGroupsComponent,
    RestrictionLimitComponent,
    DialogNewRestrictionAssetsComponent,
    DialogNewRestrictionAssetClassComponent,
    DialogNewRestrictionLimitComponent,
    DialogAddProductComponent,
    DialogSelectRangeComponent,
    ChartFutureValueComponent,
    ChartCashFlowComponent,
    ChartPieAllocationComponent,
    ChartPieGoalIncomeComponent,
    ChartFrontierComponent,
    InvestorDataComponent,
    InvestorAccountDataComponent,
    InvestorPasswordComponent,
    InvestorOrdersComponent,
    DialogGoalComponent,
    DialogIncomeComponent,
    DialogOrderComponent,
    PerformanceAnalysisComponent,
    SafePipe,
    CnpjPipe,
    DialogClientFiltersComponent,
    DialogClientGroupComponent,
    TableInvestorAccountsComponent,
    DialogPdiSettingComponent,
    DialogGroupInvestorsComponent,
    DialogSimpleEditInvestorComponent,
    ListOfManagersComponent,
    FundPerformanceComponent,
    selectProductComponent.SelectProductComponent,
    FundDataExtraInfoComponent,
    FundsComponent,
    NavigateAndDiscoverComponent,
    PerformanceAnalyzeComponent,
    PerformanceSelectComponent
  ],
  entryComponents: [
    DialogConfirmComponent,
    DialogNewRestrictionAssetsComponent,
    DialogNewRestrictionAssetClassComponent,
    DialogNewRestrictionLimitComponent,
    DialogGoalComponent,
    DialogIncomeComponent,
    DialogOrderComponent,
    DialogClientFiltersComponent,
    DialogClientGroupComponent,
    DialogPdiSettingComponent,
    DialogGroupInvestorsComponent,
    DialogSimpleEditInvestorComponent
  ]
})
export class SharedModule { }
