import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogSelectRangeComponent } from '../navigate-and-discover/dialog-select-range/dialog-select-range.component';

import { Range } from '../navigate-and-discover/fund-performance/fund-performance.component';


export interface IntervalSelected {
  type: string;
  range?: Range;
}


@Component({
  selector: 'app-chart-interval-type-selector',
  templateUrl: './chart-interval-type-selector.component.html',
  styleUrls: ['./chart-interval-type-selector.component.css']
})
export class ChartIntervalTypeSelectorComponent implements OnInit {


  intervalType = 'GREAT';

  intervalTypes = [{ type: 'GREAT', label: 'label.great'}, { type: 'ON_YEAR', label: 'label.on-year'},
  { type: 'ONE_YEAR', label: 'label.one-year'}, { type: 'TWO_YEARS', label: 'label.two-years'}, { type: 'RANGE', label: 'label.range'}];

  @Output() intervalSelected: EventEmitter<IntervalSelected> = new EventEmitter<IntervalSelected>();

  range: Range;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.intervalSelected.emit({type: this.intervalType});
  }
  setChartIntervalType(type) {
    if (type === 'RANGE') {
      this.openSelectRangeDialog();
    } else {
      this.intervalType = type;
      this.intervalSelected.emit({type: this.intervalType});
    }
  }

  chartIntervalSelected(op) {
    return this.intervalType === op;
  }

  openSelectRangeDialog() {
    const dialogRef = this.dialog.open(DialogSelectRangeComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.range = {
          beginDate: result.beginControl,
          endDate: result.endControl
        };
        this.intervalType = 'RANGE';
        this.intervalSelected.emit({type: this.intervalType, range: this.range});
      }
    });
  }
}
