<mat-tab-group>
  <mat-tab [label]="'label.comparator' | translate">
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex>
        <div class="product-card-wrapper">
          <mat-card [ngStyle]="{'border-left-color': benchmark.color}" class="product-card">
            <div fxLayout="row">
              <div fxFlex>
                <strong>{{benchmark.name}}</strong>
              </div>
              <div fxFlex class="benchmark-wrapper">
                <strong>BENCHMARK</strong>
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex></div>
              <div fxFlex>{{benchmark.name}}</div>
              <div fxFlex></div>
              <div fxFlex></div>
            </div>
            <div fxLayout="row">
              <div fxFlex class="product-value">{{benchmark.absolute_profitability}}%</div>
              <div fxFlex class="product-value">{{benchmark.relative_profitability}}%</div>
              <div fxFlex class="product-value">{{benchmark.volatility}}%</div>
              <div fxFlex class="product-value">{{benchmark.sharpe}}</div>
            </div>
            <div fxLayout="row">
              <div fxFlex>{{'label.absolute_profitability' | translate}}</div>
              <div fxFlex>{{'label.relative_profitability' | translate}}</div>
              <div fxFlex>{{'label.volatility' | translate}}</div>
              <div fxFlex>{{'label.sharpe' | translate}}</div>
            </div>
          </mat-card>
          <mat-card [ngStyle]="{'border-left-color': product.color}" class="product-card" *ngFor="let product of products; let i = index">
            <div fxLayout="row">
              <div fxFlex>
                <strong>{{product.name}}</strong>
              </div>
              <div fxFlex class="product-list-op-wrapper">
                <mat-icon (click)="removeProduct(i)">delete</mat-icon>
              </div>
            </div>
            <div *ngIf="!product.is_wallet" fxLayout="row">
              <div fxFlex></div>
              <div fxFlex>{{benchmark.name}}</div>
              <div fxFlex></div>
              <div fxFlex></div>
            </div>
            <div *ngIf="!product.is_wallet" fxLayout="row">
              <div fxFlex class="product-value">{{product.absolute_profitability}}%</div>
              <div fxFlex class="product-value">{{product.relative_profitability}}%</div>
              <div fxFlex class="product-value">{{product.volatility}}%</div>
              <div fxFlex class="product-value">{{product.sharpe}}</div>
              
            </div>
            <div *ngIf="!product.is_wallet" fxLayout="row">
              <div fxFlex>{{'label.absolute_profitability' | translate}}</div>
              <div fxFlex>{{'label.relative_profitability' | translate}}</div>
              <div fxFlex>{{'label.volatility' | translate}}</div>
              <div fxFlex>{{'label.sharpe' | translate}}</div>
            </div>
            <div *ngIf="product.is_wallet" fxLayout="row">
              <div fxFlex>
                <mat-slider class="wallet-slider" max="100" [value]="product.wallet_value" disabled></mat-slider>
              </div>
              <div fxFlex>
                <strong class="wallet-value">{{product.wallet_value}}%</strong>
              </div>
            </div>
          </mat-card>
          
          <button mat-raised-button color="primary" class="add-product-button" (click)="openAddProductDialog()">{{'btn.add-product' | translate}}</button>
        </div>
      </div>
      
      <app-fund-performance #compareChart fxFlex [products]="products" (benchmarkChange)="benchmarkChange($event)"></app-fund-performance>
      
    </div>
  </mat-tab>
  <mat-tab [label]="'label.list-of-funds' | translate">
    
    <div fxLayout="row" fxLayoutAlign="end">
      <mat-form-field class="fund-type-filter">
        <mat-label>{{'label.type' | translate}}</mat-label>
        <mat-select (selectionChange)="changeFundType($event)" [(value)]="fundType">
          <mat-option *ngFor="let ft of fundTypes" [value]="ft.type">
            {{ft.name | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 best-funds-table">
        <!-- Fund Column -->
        <ng-container matColumnDef="fund">
          <th mat-header-cell *matHeaderCellDef> {{'label.fund' | translate}} </th>
          <td fxLayout="row"  mat-cell *matCellDef="let fund"> 
              <div fxLayout="column" fxLayoutAlign="center" class="fund-position">
                <div>
                  <strong>
                    {{fund.position}}º
                  </strong>
                </div>
              </div> 
              <div>
                <div>
                  {{fund.fund}}
                </div>
                <div class="cnpj-text">
                  <mat-icon *ngIf="fund.position < 4" class="cnpj-icon"  [ngClass]="'icon-top-' + fund.position">  monetization_on </mat-icon>
                  {{fund.cnpj}}
                </div> 
              </div>
          </td>
        </ng-container>
      
        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> {{'label.type' | translate}} </th>
          <td mat-cell *matCellDef="let fund"> {{fund.type | fundTypeToKey | translate}} </td>
        </ng-container>
      
        <!-- Risk Column -->
        <ng-container matColumnDef="risk">
          <th mat-header-cell *matHeaderCellDef> {{'label.risk' | translate}} </th>
          <td mat-cell *matCellDef="let fund"> <span class="risk-level" [ngStyle]="{'opacity': (2 * (i+1) / 10)}" *ngFor="let item of [].constructor(fund.risk); let i = index">| </span> </td>
        </ng-container>
      
        <!-- Value Column -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> % </th>
          <td mat-cell *matCellDef="let fund"> <strong>{{fund.value}}%</strong> </td>
        </ng-container>

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox> <span class="best-funds-checkbox-title"> {{'label.compare' | translate}} </span>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)">
        </tr>
      </table>
  </mat-tab>
  <mat-tab [label]="'label.list-of-managers' | translate">
    <app-list-of-managers></app-list-of-managers>
  </mat-tab>

</mat-tab-group>