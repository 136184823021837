import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

// import {, , ,
//   ErrorStateMatcher, ShowOnDirtyErrorStateMatcher, , ,
//   , ,
// } from '@angular/material/';

import { StorageService as storage } from '../../services/storage.service';


export const MY_FORMATS_DATES = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'ddd, DD MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatTableModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatBottomSheetModule,
    MatTabsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRippleModule,
    MatDialogModule,
    MatSliderModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatChipsModule,
    MatRadioModule,
    MatBadgeModule,
    MatStepperModule,
    MatGridListModule
  ],
  exports: [
    FlexLayoutModule,
    MatButtonModule,
    MatTableModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatBottomSheetModule,
    MatTabsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRippleModule,
    MatDialogModule,
    MatSliderModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatChipsModule,
    MatRadioModule,
    MatBadgeModule,
    MatStepperModule,
    MatGridListModule
  ],
  declarations: [],
  providers: [
    // { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_DATES },
    // { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } }
  ]
})
export class MaterialModule {
  constructor(private dateAdapter?: DateAdapter<any>,
    private i18nPaginator?: MatPaginatorIntl,
    private translate?: TranslateService
  ) {

    // set default lang
    let store_lang = storage.get('lang');

    if (store_lang) {
      store_lang = (store_lang.split('-'))[0];
    }
    this.dateAdapter.setLocale(store_lang || 'pt');

    // Paginator
    const msg_ip = _('text.paginate-itens-page');
    const msg_n = _('text.paginate-next');
    const msg_p = _('text.paginate-previous');

    this.translate.get(msg_ip).subscribe(m => {
      this.i18nPaginator.itemsPerPageLabel = m;
    });

    this.translate.get(msg_n).subscribe(m => {
      this.i18nPaginator.nextPageLabel = m;
    });

    this.translate.get(msg_p).subscribe(m => {
      this.i18nPaginator.previousPageLabel = m;
    });
  }
}
