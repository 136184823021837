<mat-list-item *ngIf="userData" (click)="sideClose()" class="nav-user">
  <img mat-list-avatar [src]="userData.avatar" *ngIf="userData.avatar">
  <h3 mat-line>{{ userData.name }}</h3>
  <p mat-line class="userSubLine">
    <span>{{ userData.email }}</span>
  </p>
</mat-list-item>

<mat-divider></mat-divider>

<mat-list-item *ngFor="let item of menuData; trackBy: trackByFn" routerLink="{{ item.link }}" routerLinkActive="active-menu" 
  (click)="sideClose(item.logout)" [routerLinkActiveOptions]="{exact:item.exact}" class="nav-menu">
  <mat-icon matListIcon>{{item.icon}}</mat-icon>
  <p mat-line>
    {{item.label | translate}}
  </p>
</mat-list-item>