<div  class="chart-addons">
    <div fxLayout.md="row" fxLayout.sm="column">
      <div fxFlex="40%" class="op-button-wrapper">
        <span class="chart-op" (click)="setBenchmark(cdi)" [ngClass]="{'chart-op-selected' : chartBenchmarkSelected('CDI')}">{{'label.cdi' | translate}}</span>
        <span class="chart-op" (click)="setBenchmark(ipca)" [ngClass]="{'chart-op-selected' : chartBenchmarkSelected('IPCA')}">{{'label.ipca' | translate}}</span>
        <span class="chart-op" (click)="setBenchmark(ibov)" [ngClass]="{'chart-op-selected' : chartBenchmarkSelected('IBOV')}">{{'label.ibov' | translate}}</span>
        <span class="chart-op" (click)="setBenchmark(poup)" [ngClass]="{'chart-op-selected' : chartBenchmarkSelected('POUP')}">{{'label.poup' | translate}}</span>
      </div>
      <div fxFlex="60%" class="map-buttons op-button-wrapper">
        <app-chart-interval-type-selector (intervalSelected)="setChartIntervalType($event)"> </app-chart-interval-type-selector>
      </div>
    </div>
   <div>
    <highcharts-chart *ngIf="!update" [Highcharts]="Highcharts" (chartInstance)="setCompareChartInstance($event)" [options]="chartData" style="width: 100%; height: 400px; display: block;">
    </highcharts-chart>
  </div>
</div>