import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { HttpParams } from '@angular/common/http';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent implements OnInit {

  constructor(private utilService: UtilService, private appConfigService: AppConfigService) {}
  enableDebug = false;
  requestID = null;
  env = null;

  mps_sources = {
    testing: '/mps\-testing\-[0-9]+/',
    sandbox: '/mps\-sandbox\-[0-9]+/',
    production: '/mps\-[0-9]+/',
  };

  pms_sources = {
    testing: '/pms\-testing\-[0-9]+/',
    sandbox: '/pms\-sandbox\-[0-9]+/',
    production: '/pms\-[0-9]+/',
  };


  ngOnInit(): void {
    this.env = this.appConfigService.config['API_ENV'];
    this.utilService.enableRequestDebug.subscribe(v => {
      // console.log('DEBUG: ' + v);
      this.enableDebug = v;
    });

    this.utilService.requestID.subscribe(v => {
      // console.log('REQ_ID: ' + v);
      this.requestID = v;
    });
  }

  buildUrlPMS(): string {
    if (!this.requestID || this.enableDebug !== true) {
      return null;
    }
    const data = {
      width: 1447,
      relative: 3600,
      page: 1,
      sortOrder: 'asc',
      sortField: 'timestamp',
      rangetype: 'relative',
      q: 'RequestID:' + this.requestID + ' AND source:' + this.pms_sources[this.env]
    };

    const qs = Object.keys(data).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k])).join('&');
    return 'https://graylog.robobanker.com.br/search?' + qs.toString();
  }

  buildUrlMPS(): string {
    if (!this.requestID || this.enableDebug !== true) {
      return null;
    }
    const data = {
      width: 1447,
      relative: 3600,
      page: 1,
      sortOrder: 'asc',
      sortField: 'timestamp',
      rangetype: 'relative',
      q: 'RequestID:' + this.requestID + ' AND source:' + this.mps_sources[this.env]
    };

    const qs = Object.keys(data).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k])).join('&');
    return 'https://graylog.robobanker.com.br/search?' + qs.toString();
  }

}
