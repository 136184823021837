<mat-drawer-container [hasBackdrop]="true">
    <mat-drawer #drawer mode="over">
        <mat-nav-list dense>
            <app-navitems [sidenav]="drawer"></app-navitems>
        </mat-nav-list>
    </mat-drawer>

    <!-- <mat-drawer #priceDrawer mode="over" position="end">
        <app-notification-prices></app-notification-prices>
    </mat-drawer>

    <mat-drawer #notifyDrawer mode="over" position="end">
        <app-notification></app-notification>
    </mat-drawer> -->

    <mat-drawer-content [class.bgimg]="applyBg">
        <!--header-->
        <app-header class="mat-elevation-z6" [sidenav]="drawer"></app-header>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="mainContent">
            <!--router-->
            <div fxFlex="100%">
                <router-outlet></router-outlet>
            </div>
        </div>

        <!-- debug -->
        <div fxLayout="row" fxLayoutAlign="start">
            <div fxFlex="100%">
                <app-debug></app-debug>
            </div>
        </div>

        <!--footer-->
        <div fxLayout="row" fxLayoutAlign="start">
            <div fxFlex="100%">
                <app-footer></app-footer>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>