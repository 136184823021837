import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, DoBootstrap, ApplicationRef, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe, CurrencyPipe, PercentPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClient } from '@angular/common/http';
import {
  TranslateCompiler, TranslateLoader, TranslateModule, TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler, MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';

import { StorageService as storage } from './shared/services/storage.service';

// Locale
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';

// Router
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';

// Service
import { ThemeService } from './shared/services/theme.service';
import { HttpProvide } from './shared/providers/http.provider';
import { SharedModule } from './shared/modules/shared/shared.module';
import { PartnerService } from './shared/services/partner.service';
import { ManagerService } from './shared/services/manager.service';
import { PmsExtraModuleService } from './shared/services/pms-extra-module.service';
import { SystemService } from './shared/services/system.service';
import { EnumService } from './shared/services/enum.service';
import { AssetService } from './shared/services/asset.service';
import { StorageService } from './shared/services/storage.service';
import { UtilService } from './shared/services/util.service';
import { LoadingService } from './shared/services/loading.service';
import { NavitemsService } from './shared/services/navitems.service';
import { UpdateService } from './shared/services/update.service';
import { AppConfigService } from './shared/services/app-config.service';

import { MaterialModule } from './shared/modules/material/material.module';
import { VendorModule } from './shared/modules/vendor/vendor.module';
import { HomeComponent } from './home/home.component';
import { Error404Component } from './error404/error404.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavitemsComponent } from './shared/components/navitems/navitems.component';
import { DialogAssetComponent } from './shared/components/dialog-asset/dialog-asset.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { NotificationPricesComponent } from './shared/components/notification-prices/notification-prices.component';
import { DebugComponent } from './shared/components/debug/debug.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Error404Component,
    HeaderComponent,
    FooterComponent,
    NavitemsComponent,
    DialogAssetComponent,
    NotificationComponent,
    NotificationPricesComponent,
    DebugComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    VendorModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      // compiler: {
      //   provide: TranslateCompiler,
      //   useClass: TranslateMessageFormatCompiler
      // },
      isolate: false,
      useDefaultLang: true
    })
  ],
  entryComponents: [
    DialogAssetComponent
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    PercentPipe,
    ThemeService,
    PartnerService,
    ManagerService,
    SystemService,
    AssetService,
    EnumService,
    StorageService,
    UtilService,
    LoadingService,
    NavitemsService,
    PmsExtraModuleService,
    UpdateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpProvide,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
      // useValue: ['pt', 'es', 'en']
    },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: { locales: ['pt', 'es', 'en'] }
    },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigService) => {
        return () => {
          return appConfig.loadAppConfig();
        };
      },
      multi: true,
      deps: [AppConfigService]
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule implements DoBootstrap {
  langs = {
    'pt': localePt,
    'es': localeEs,
    'en': localeEn
  };

  constructor(private translate: TranslateService) {

    Object.keys(this.langs).map(k => registerLocaleData(this.langs[k], k));

    // translate
    this.translate.addLangs(Object.keys(this.langs));
    this.translate.setDefaultLang('pt');

    // Default language
    let use_lang = 'pt';
    if (storage.get('lang')) {
      use_lang = storage.get('lang');
    } else if (this.translate.getBrowserLang() in Object.keys(this.langs)) {
      use_lang = this.translate.getBrowserLang();
    }

    storage.save('lang', use_lang);
    this.translate.use(use_lang);
  }

  ngDoBootstrap(appRef: ApplicationRef) {
    appRef.bootstrap(AppComponent);
  }
}

