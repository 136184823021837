import { Component, OnInit, ViewChild, QueryList, ElementRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Product } from '../navigate-and-discover.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

marker('label.stocks');
marker('label.multimarket');
marker('label.public-welfare');
marker('label.fixed-income');
marker('label.share-holders');
marker('label.patrimony');


interface ProductData {
  patrimony: number;
  shareHolders: number;
  funds: number;
  fundCount: {stocks: number, multimarket: number, publicWelfare: number, fixedIncome: number};
}

@Component({
  selector: 'app-list-of-managers',
  templateUrl: './list-of-managers.component.html',
  styleUrls: ['./list-of-managers.component.css']
})
export class ListOfManagersComponent implements OnInit {

  patrimonyData: Object;
  shareHoldersData: Object;
  fundsData: Object;

  shareHoldersChartData: Object;
  patrimonyChartData: Object;
  fundsChartData: Object;
  shareHoldersPieChartData: Object;
  patrimonyPieChartData: Object;
  fundsPieChartData: Object;

  Highcharts = Highcharts;

  updating = true;

  productData: ProductData;

  productControl = new FormControl();

  colors = {stocks: '#0000FF', multimarket: '#00FF00', publicWelfare: '#FF0000', fixedIncome: '#FFFF00'};

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.productControl.setValue(  {color: '#ff0000', name: 'ALASKA BLACK FIC FIA-BDR NÍVEL I', absolute_profitability: -45.47,
    relative_profitability: -380.25, volatility: 35.92, sharpe: null});

    this.onSelectionChange(this.productControl.value);
    this.productData = {
      patrimony: 100000000,
      shareHolders: 250121,
      funds: 140,
      fundCount: {stocks: 20, multimarket: 30, publicWelfare: 40, fixedIncome: 50}
    };
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getFundsData(p: Product) {
    const fundA = [];
    const fundB = [];
    const fundC = [];
    const fundD = [];
    for (let i = 1; i <= this.buildFundsInterval().length; i++) {
      fundA.push(this.getRandomInt(100000));
      fundB.push(this.getRandomInt(100000));
      fundC.push(this.getRandomInt(100000));
      fundD.push(this.getRandomInt(100000));
    }

    return {
      fundA: fundA,
      fundB: fundB,
      fundC: fundC,
      fundD: fundD
    };
  }

  getProductShareHoldersData(p: Product) {
    // Random data generated is similar
    return this.getProductPatrimonyData(p);
  }

  getProductPatrimonyData(p: Product) {
    const dataStocks = [];
    const dataMultimarket = [];
    const dataPublicWelfare = [];
    const dataFixedIncome = [];
    for (let i = 1; i <= this.buildPatrimonyInterval().length; i++) {
      dataStocks.push(this.getRandomInt(100000));
      dataMultimarket.push(this.getRandomInt(100000));
      dataPublicWelfare.push(this.getRandomInt(100000));
      dataFixedIncome.push(this.getRandomInt(100000));
    }

    return {
      stocks: dataStocks,
      multimarket: dataMultimarket,
      publicWelfare: dataPublicWelfare,
      fixedIncome: dataFixedIncome
    };
  }

  onSelectionChange(event) {
    this.updating = true;
    this.patrimonyData = this.getProductPatrimonyData(this.productControl.value);
    this.shareHoldersData = this.getProductShareHoldersData(this.productControl.value);
    this.fundsData = this.getFundsData(this.productControl.value);
    this.shareHoldersChartData = this.buildShareHoldersChartData();
    this.patrimonyChartData = this.buildPatrimonyChartData();
    this.fundsChartData = this.buildFundsChartData();
    this.shareHoldersPieChartData = this.buildShareHoldersPieChartData();
    this.patrimonyPieChartData = this.buildPatrimonyPieChartData();
    this.fundsPieChartData = this.buildFundsPieChartData();
    setTimeout(() => this.updating = false);
  }

  buildPatrimonyChartData() {

    const chart  = {
    chart: {
      type: 'area'
    },
    title: {
        text: this.translate.instant('label.patrimony')
    },

    subtitle: {
        text: ''
    },
    xAxis: {
      categories: this.buildPatrimonyInterval()
    },

    yAxis: {
        title: {
            text: ''
        }
      },


    series: [
      {name: this.translate.instant('label.stocks'), data: this.patrimonyData['stocks'], color: this.colors.stocks},
      {name: this.translate.instant('label.multimarket'), data: this.patrimonyData['multimarket'], color: this.colors.multimarket},
      {name: this.translate.instant('label.public-welfare'), data: this.patrimonyData['publicWelfare'], color: this.colors.publicWelfare},
      {name: this.translate.instant('label.fixed-income'), data: this.patrimonyData['fixedIncome'], color: this.colors.fixedIncome}
    ]


    };
    return chart;
  }

  buildShareHoldersChartData() {

    const chart  = {
      title: {
        text: this.translate.instant('label.share-holders')
    },

    subtitle: {
        text: ''
    },
    xAxis: {
      categories: this.buildShareHoldersInterval()
    },

    yAxis: {
        title: {
            text: ''
        }
      },


    series: [{name: this.translate.instant('label.stocks'), data: this.shareHoldersData['stocks'], color: this.colors.stocks},
    {name: this.translate.instant('label.multimarket'), data: this.shareHoldersData['multimarket'], color: this.colors.multimarket},
    {name: this.translate.instant('label.public-welfare'), data: this.shareHoldersData['publicWelfare'], color: this.colors.publicWelfare},
    {name: this.translate.instant('label.fixed-income'), data: this.shareHoldersData['fixedIncome'], color: this.colors.fixedIncome}]


    };
    return chart;
  }

  buildFundsChartData() {

    const chart  = {
      title: {
        text: this.translate.instant('label.funds')
    },

    subtitle: {
        text: ''
    },
    xAxis: {
      categories: this.buildFundsInterval()
    },

    yAxis: {
        title: {
            text: ''
        }
      },


    series: [{name: 'Fund A', data: this.fundsData['fundA'], color: this.colors.stocks},
    {name: 'Fund B', data: this.fundsData['fundB'], color: this.colors.multimarket},
    {name: 'Fund C', data: this.fundsData['fundC'], color: this.colors.publicWelfare},
    {name: 'Fund D', data: this.fundsData['fundD'], color: this.colors.fixedIncome}]


    };
    return chart;
  }

  buildShareHoldersInterval() {
    return [2015, 2016, 2017, 2018, 2019, 2020];
  }

  buildPatrimonyInterval() {
    return [2015, 2016, 2017, 2018, 2019, 2020];
  }

  buildFundsInterval() {
    return [2015, 2016, 2017, 2018, 2019, 2020];
  }

  buildPatrimonyPieChartData() {

    const chartData = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: 'transparent',
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{point.y}: <b>{point.percentage:.2f}%</b>'
      },
      series: [
        {
          type: 'pie',
          name: '',
          data: [
            {
              id: 1,
              name: this.translate.instant('label.stocks'),
              y: this.patrimonyData['stocks'][this.patrimonyData['stocks'].length - 1],
              color: this.colors.stocks
            },
            {
              id: 2,
              name: this.translate.instant('label.multimarket'),
              y: this.patrimonyData['multimarket'][this.patrimonyData['multimarket'].length - 1],
              color: this.colors.multimarket
            },
            {
              id: 3,
              name: this.translate.instant('label.public-welfare'),
              y: this.patrimonyData['publicWelfare'][this.patrimonyData['publicWelfare'].length - 1],
              color: this.colors.publicWelfare
            },
            {
              id: 4,
              name: this.translate.instant('label.fixed-income'),
              y: this.patrimonyData['fixedIncome'][this.patrimonyData['fixedIncome'].length - 1],
              color: this.colors.fixedIncome
            }
          ]
        }
      ],
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          showInLegend: true,
          innerSize: 100,
          depth: 45,
          dataLabels: {
            enabled: false,
          },
        }
      }
    };

    return chartData;
  }

  buildShareHoldersPieChartData() {

    const chartData = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: 'transparent',
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{point.y}: <b>{point.percentage:.2f}%</b>'
      },
      series: [
        {
          type: 'pie',
          name: '',
          data: [
            {
              id: 1,
              name: this.translate.instant('label.stocks'),
              y: this.shareHoldersData['stocks'][this.shareHoldersData['stocks'].length - 1],
              color: this.colors.stocks
            },
            {
              id: 2,
              name: this.translate.instant('label.multimarket'),
              y: this.shareHoldersData['multimarket'][this.shareHoldersData['multimarket'].length - 1],
              color: this.colors.multimarket
            },
            {
              id: 3,
              name: this.translate.instant('label.public-welfare'),
              y: this.shareHoldersData['publicWelfare'][this.shareHoldersData['publicWelfare'].length - 1],
              color: this.colors.publicWelfare
            },
            {
              id: 4,
              name: this.translate.instant('label.fixed-income'),
              y: this.shareHoldersData['fixedIncome'][this.shareHoldersData['fixedIncome'].length - 1],
              color: this.colors.fixedIncome
            }
          ]
        }
      ],
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          showInLegend: true,
          innerSize: 100,
          depth: 45,
          dataLabels: {
            enabled: false,
          },
        }
      }
    };

    return chartData;
  }

  buildFundsPieChartData() {

    const chartData = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: 'transparent',
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{point.y}: <b>{point.percentage:.2f}%</b>'
      },
      series: [
        {
          type: 'pie',
          name: '',
          data: [
            {
              id: 1,
              name: 'Fund A',
              y: this.fundsData['fundA'][this.fundsData['fundA'].length - 1],
              color: this.colors.stocks
            },
            {
              id: 2,
              name: 'Fund B',
              y: this.fundsData['fundB'][this.fundsData['fundB'].length - 1],
              color: this.colors.multimarket
            },
            {
              id: 3,
              name: 'Fund C',
              y: this.fundsData['fundC'][this.fundsData['fundC'].length - 1],
              color: this.colors.publicWelfare
            },
            {
              id: 4,
              name: 'Fund D',
              y: this.fundsData['fundD'][this.fundsData['fundD'].length - 1],
              color: this.colors.fixedIncome
            }
          ]
        }
      ],
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          showInLegend: true,
          innerSize: 100,
          depth: 45,
          dataLabels: {
            enabled: false,
          },
        }
      }
    };

    return chartData;
  }

}
