<mat-toolbar color="primary">
  <mat-toolbar-row color="primary">
    <div fxLayoutAlign="start center" fxLayoutGap="2%">

      <button mat-icon-button (click)="sidenav.open()">
        <mat-icon>menu</mat-icon>
      </button>

      <img [src]="urlLogo || 'assets/images/logo_transparent.png'" alt="RoboBanker" height="50" class="logo">
    </div> 

    <div class="spacer"></div>

    <div fxLayout="row" fxLayoutGap="70px">
      <form [formGroup]="formLang" fxLayoutAlign="start center" class="language">
        <mat-form-field appearance="legacy">
          <span matPrefix>
            <mat-icon>language</mat-icon> &nbsp;
          </span>
          <mat-select formControlName="lang">
            <mat-option *ngFor="let opt of availableLangs" [value]="opt.value">
              {{opt.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <div fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="start center" *ngIf="userData && userData['show_header']"
        @fadeInAnimation>

        <button mat-icon-button matTooltip="Alertas de cotação">
          <mat-icon matBadge="2" matBadgeColor="accent">show_chart</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Notificações">
          <mat-icon matBadge="8" matBadgeColor="accent">notifications</mat-icon>
        </button>
      </div>

      <div matRipple fxLayout="row" fxLayoutGap="4%" fxLayoutAlign="start center" fxHide.xs
        *ngIf="userData && userData['show_header']" class="user-data" [matMenuTriggerFor]="userMenu" @fadeInAnimation>
        <div>
          <img [src]="userData['avatar']" [alt]="userData['name']" width="50" height="50"
            class="user-avatar mat-elevation-z6" *ngIf="userData['avatar']">
          <img src="assets/images/user.png" [alt]="userData['name']" width="50" height="50"
            class="user-avatar mat-elevation-z6" *ngIf="!userData['avatar']">
        </div>
        <div fxLayout="column">
          <span class="user-name">{{ userData['name'] }}</span>
          <span class="user-email">{{ userData['email'] }}</span>
        </div>
      </div>

      <mat-menu #userMenu="matMenu">
        <a mat-menu-item *ngFor="let opt of headerMenu" [routerLink]="opt.link" [disabled]="opt.disabled"
          [routerLinkActiveOptions]="{exact:opt.exact}" routerLinkActive="active-menu" (click)="userLogout(opt.logout)">
          <mat-icon>{{ opt.icon }}</mat-icon>
          <span>{{ opt.label | translate }}</span>
        </a>
      </mat-menu>
    </div>

  </mat-toolbar-row>
</mat-toolbar>

<mat-progress-bar mode="indeterminate" color="warn" *ngIf="isLoading"></mat-progress-bar>