import { Injectable, Output, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UpdateService } from './update.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavitemsService } from './navitems.service';
import { UtilService } from './util.service';
import { StorageService as storage } from './storage.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  @Output() managerData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Output() partnersData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Output() investors: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient,
    private updateService: UpdateService,
    private router: Router,
    private snackBar: MatSnackBar,
    private navitemsService: NavitemsService,
    private utilService: UtilService
  ) { }

  /**
 * Intercept HandleError
 * @param error HTTP Response
 */
  private handleError(error: Response | any, router: Router): Promise<any> {
    // 401 erros authorization
    if (error.status === 401) {
      const json = error.error;
      let msg: string;
      if ('errors' in json && 'jwt' in json['errors'] && json['errors']['jwt'].length > 0) {
        msg = json['errors']['jwt'][0];
      }

      // logout if expired token
      if (msg === 'expired token' || msg === 'jwt decode error' || msg === 'this jwt was revoked' || msg === 'this jwt is wrong'
        || msg === 'this jwt is wrong, invalid or missing') {
        this.snackBar.open('Sua sessão expirou. Entre novamente.', 'OK', {
          duration: 10000
        });
        this.navitemsService.routerItems.next(null);
        storage.deleteManagerJwt();
        router.navigate(['/investor/login']);
      }
    }

    return Promise.reject(error);
  }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.updateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * Signup
   * @param data Data Manager Signup
   */
  signup(data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/manager']);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Confirm
   * @param data Data Manager Confirm
   */
  confirm(data: Object, jwt_confirm: string): Promise<any> {
    const url = this.utilService.makeUri(['/manager', 'confirm', { jwt: jwt_confirm }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Manager Login
   * @param data User and Password Data
   */
  login(data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/manager/auth']);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get manager info
   * @param manager_uuid Manager UUID
   * @param jwt User JWT
   */
  getInfo(manager_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/manager', manager_uuid, { jwt: jwt || storage.getManagerJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update Manager Information
   * @param manager_uuid Manager UUID
   * @param data Manager Data Update
   * @param jwt JWT
   */
  updateInfo(manager_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/manager', manager_uuid, { jwt: jwt || storage.getManagerJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get manager info
   * @param manager_uuid Manager UUID
   * @param jwt User JWT
   */
  getAccounts(manager_uuid: string, filter: Object = {}, jwt?: string): Promise<any> {
    filter['jwt'] = jwt || storage.getManagerJwt();

    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'investor-accounts', filter
    ]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Ger Partners
   * @param filter Filter data Object
   */
  getPartners(filter = {}): Promise<any> {
    filter['jwt'] = storage.getManagerJwt();
    const url = this.utilService.makeUri(['/manager/partners', filter]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Investor Group
   * @param manager_uuid Manager UUID
   * @param data Data Classification
   * @param jwt JSON Web Token
   */
  createInvestorGroup(manager_uuid: string, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'investor-group', { jwt: jwt || storage.getManagerJwt() }
    ]);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get Investor Groups
   * @param manager_uuid Manager UUID
   * @param jwt JSON Web Token
   */
  getInvestorGroups(manager_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'investor-groups', { jwt: jwt || storage.getManagerJwt() }
    ]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update Investor Group
   * @param manager_uuid Manager UUID
   * @param group_id Group ID
   * @param data Data Update
   * @param jwt JSON Web Token
   */
  updateInvestorGroup(manager_uuid: string, group_id: number, data: Object, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'investor-group', group_id, { jwt: jwt || storage.getManagerJwt() }
    ]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Investor Group
   * @param manager_uuid Manager UUID
   * @param group_id Group ID
   * @param jwt JSON Web Token
   */
  getInvestorGroup(manager_uuid: string, group_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'investor-group', group_id, { jwt: jwt || storage.getManagerJwt() }
    ]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Investor Group
   * @param manager_uuid Manager UUID
   * @param group_id Group ID
   * @param jwt JSON Web Token
   */
  deleteInvestorGroup(manager_uuid: string, group_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'investor-group', group_id, { jwt: jwt || storage.getManagerJwt() }
    ]);

    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
 * Create a Manager Allocation Restriction
 * @param data Object Manager Allocation Restriction Data
 * @param manager_uuid Manager UUID
 * @param jwt JWT
 */
  createManagerRestriction(data: Object, manager_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update a Manager Allocation Restriction
   * @param restriction_id Restriction ID
   * @param data Object Manager Allocation Restriction Data
   * @param manager_uuid Manager UUID
   * @param jwt JWT
   */
  updateManagerRestriction(restriction_id: number, data: Object, manager_uuid: string, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get Manager Restrictions
   * @param manager_uuid Manager UUID
   * @param filter Filter
   * @param jwt JWT
   */
  getManagerRestrictions(manager_uuid: string, filter = {}, jwt?: string): Promise<any> {
    filter['jwt'] = jwt || storage.getInvestorJwt();

    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restrictions', filter
    ]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Manager Allocation Restriction
   * @param manager_uuid Manager UUID
   * @param restriction_id Manager Alloction Restriction ID
   * @param jwt JWT
   */
  getManagerRestriction(manager_uuid: string, restriction_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Manager Allocation Restriction
   * @param manager_uuid Manager UUID
   * @param restriction_id Manager Allocation Restriction ID
   * @param jwt JWT
   */
  deleteManagerRestriction(manager_uuid: string, restriction_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Manager Alloction Restriction Asset
   * @param data Object Manager Allocation Restriction Asset Data
   * @param manager_uuid Manager UUID
   * @param restriction_id Manager Allocation Restriction ID
   */
  createManagerRestrictionAsset(data: Object, manager_uuid: string, restriction_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, 'asset', { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Manager Alloction Restriction Asset
   * @param data Object Manager Allocation Restriction Asset Data
   * @param manager_uuid Manager UUID
   * @param restriction_id Manager Allocation Restriction ID
   * @param restriction_asset_id Manager Allocation Restriction Asset ID
   */
  updateManagerRestrictionAsset(data: Object, manager_uuid: string, restriction_id: number,
    restriction_asset_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, 'asset',
      restriction_asset_id, { jwt: jwt || storage.getInvestorJwt() }
    ]);
    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get list Manager Restriction Assets
   * @param manager_uuid Manager UUID
   * @param restriction_id Manager Allocation Restriction ID
   * @param jwt JWT
   */
  getManagerRestrictionAssets(manager_uuid: string, restriction_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, 'assets', { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Manager Restriction Asset
   * @param manager_uuid Manager UUID
   * @param restriction_id Manager Allocation Restriction ID
   * @param restriction_asset_id Manager Allocation Restriction Asset ID
   * @param jwt JWT
   */
  getManagerRestrictionAsset(manager_uuid: string, restriction_id: number, restriction_asset_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, 'asset',
      restriction_asset_id, { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Manager Restriction Asset
   * @param manager_uuid Manager UUID
   * @param restriction_id Manager Allocation Restriction ID
   * @param restriction_asset_id Manager Allocation Restriction Asset ID
   * @param jwt JWT
   */
  deleteManagerRestrictionAsset(manager_uuid: string, restriction_id: number, restriction_asset_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, 'asset',
      restriction_asset_id, { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Manager Restriction Asset Class
   * @param manager_uuid Manager UUID
   * @param data Restriction Asset Class Data
   * @param restriction_id Restriction ID
   * @param jwt JWT
   */
  createManagerRestrictionAssetClass(data: Object, manager_uuid: string, restriction_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id,
      'asset-classification', { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update a Manager Restriction Asset Class
   * @param data Restriction Asset Classe Data
   * @param manager_uuid Manager UUID
   * @param restriction_id Restriction ID
   * @param restriction_asset_classification_id Restriction Asset Class ID
   * @param jwt JWT
   */
  updateManagerRestrictionAssetClass(data: Object, manager_uuid: string, restriction_id: number,
    restriction_asset_classification_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, 'asset-classification',
      restriction_asset_classification_id, { jwt: jwt || storage.getManagerJwt() }]
    );
    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get list Manager Restriction Asset Classes
   * @param manager_uuid Manager UUID
   * @param restriction_id Restriction ID
   * @param jwt JWT
   */
  getManagerRestrictionAssetClasses(manager_uuid: string, restriction_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id,
      'asset-classifications', { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Manager Restriction Asset Class
   * @param manager_uuid Manager UUID
   * @param restriction_id Restriction ID
   * @param restriction_asset_classification_id Restriction Asset Classification ID
   * @param jwt JWT
   */
  getManagerRestrictionAssetClass(manager_uuid: string, restriction_id: number,
    restriction_asset_classification_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, 'asset-classification',
      restriction_asset_classification_id, { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Manager Restriction Asset Class
   * @param manager_uuid Manager UUID
   * @param restriction_id Restriction ID
   * @param restriction_asset_classification_id Restriction Asset Classification ID
   * @param jwt JWT
   */
  deleteManagerRestrictionAssetClass(manager_uuid: string, restriction_id: number,
    restriction_asset_classification_id: number, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri([
      '/manager', manager_uuid, 'allocation-restriction', restriction_id, 'asset-classification',
      restriction_asset_classification_id, { jwt: jwt || storage.getManagerJwt() }
    ]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }
}
