import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fundTypeToKey'
})
export class FundTypeToKeyPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case 'STOCK': return 'text.stocks-free';
      case 'STOCK_INDEX': return 'text.stocks-index';
      case 'STOCK_OUTSHORE': return 'text.stocks-outshore';
      case 'REAL_STATE': return 'text.real-state-fond';
      case 'MULTIMARKET': return 'text.multimarket';
      case 'EXCHANGE': return 'text.exchange';
      case 'FIXED_INCOME': return 'text.fixed-income';
    }

    return value;
  }

}
