import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LoadingService } from '../../services/loading.service';
import { NavitemsService } from '../../services/navitems.service';
import { SidenavUserdata } from '../../interfaces/sidenav-userdata';
import { fadeInAnimation } from '../../animations/fadein.animation';
import { SidenaItems } from '../../interfaces/sidena-items';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StorageService as storage } from '../../services/storage.service';

declare var window;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInAnimation]
})
export class HeaderComponent implements OnInit {
  @Input() sidenav;
  @Input() notify_price;
  @Input() notify;

  headerTitle = 'RoboBanker';
  partnerName: string;
  moduleName: string;
  urlLogo: string;
  formLang: FormGroup;

  isLoading = false;
  userData: SidenavUserdata;
  headerMenu: SidenaItems[];
  userAccounts: Object[];

  default_lang = 'pt';

  availableLangs = [
    {
      label: 'POR',
      value: 'pt'
    },
    {
      label: 'ENG',
      value: 'en'
    },
    {
      label: 'ESP',
      value: 'es'
    },
    // {
    //   label: 'Italiano',
    //   value: 'it'
    // },
    // {
    //   label: '中国',
    //   value: 'zh'
    // }
  ];

  logo: Object;

  constructor(private themeService: ThemeService,
      private loadingService: LoadingService,
      private navitemsService: NavitemsService,
      private fb: FormBuilder
  ) { }

  ngOnInit() {
    // language form
    this.formLang = this.fb.group({
      lang: [ storage.get('lang') || 'pt' ]
    });

    this.formLang.get('lang').valueChanges.subscribe(l => {
      storage.save('lang', l);
      this.themeService.language.next(l);
      window.location.reload();
    });

    // header title
    this.themeService.headerTitle.subscribe( v => this.headerTitle = v );
    this.themeService.moduleName.subscribe(v => this.moduleName = v);
    this.themeService.partnerName.subscribe(v => this.partnerName = v);
    this.themeService.logo.subscribe(v => this.urlLogo = v);

    // loading
    this.loadingService.isLoading.subscribe(loading => this.isLoading = loading);

    // user data
    this.navitemsService.userData.subscribe(data => this.userData = data);

    // header menu
    this.navitemsService.routerItems.subscribe(data => this.headerMenu = data);
  }

  /**
   * User Logout
   * @param logout Logout Function
   */
  userLogout(logout: Function) {
    if (logout) {
      logout();
    }
  }
}
