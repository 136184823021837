import { Injectable, Output } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { Country } from '../interfaces/country';
import { Currency } from '../interfaces/currency';
import { Language } from '../interfaces/language';
import { Timezone } from '../interfaces/timezone';
import { Asset } from '../interfaces/asset';
import { AssetClass } from '../interfaces/asset-class';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { AppConfigService } from './app-config.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  @Output() enableRequestDebug: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() requestID: BehaviorSubject<string> = new BehaviorSubject(null);

  /**
   * Convert Object in Form Data
   * @param data Object translate encodeURI
   */
  static buildPostData(data: Object): string {
    let fromData = '';
    if (data) {
      Object.keys(data).forEach((value) => {
        fromData += value + '=' + encodeURIComponent(data[value]) + '&';
      });
    }
    return fromData;
  }

  constructor(private confService: AppConfigService) {}

  /**
   * Build URL API
   * @param data Data for Build
   */
  makeUri(data: any[]): string {
    const config = this.confService.config;
    let url: string = config.API_HOST;
    let qs = '';

    // port
    if ('API_PORT' in config && config['API_PORT'] !== 80 && config['API_PORT'] !== 443) {
      url += ':' + config['API_PORT'];
    }

    // base api
    url += '/' + config.API_BASE;

    // data
    data.forEach((elem) => {
      if (!elem) { return; }
      if (typeof (elem) === 'object') {
        Object.keys(elem).sort().forEach((key) => {
          qs += encodeURIComponent(key) + '=' + encodeURIComponent(elem[key]) + '&';
        });
      } else {
        url += '/' + elem;
      }
    });

    // double /
    url = url.replace(/\/{2,}/g, '/');

    // query string
    if (qs.length > 0) {
      qs = qs.replace(/\&$/, '');
      url += '?' + qs;
    }

    // protocolo http or https
    url = config.API_PROT + '://' + url;

    // debug
    if (env.production === false && config.API_DEBUG === true) {
      console.log('MAKE URI: ' + url);
    }
    return url;
  }

  /**
   * Builda data post
   * @param data Data for post
   */
  buildPostData(data: Object): string {
    let fromData = '';
    if (data) {
      Object.keys(data).forEach((value) => {
        fromData += value + '=' + encodeURIComponent(data[value]) + '&';
      });
    }

    if (fromData.length === 0) {
      fromData = ' ';
    }
    return fromData;
  }

  /**
   * Filter Country Autocomplete
   * @param countries Country Array
   * @param value Value Filter
   */
  filter_country(countries: Country[], value: string): Country[] {
    if (!value || typeof (value) === 'object') {
      return;
    }
    const filterValue = value.toLowerCase();
    if (!countries) {
      return null;
    }

    return countries.filter(o => {
      return o['name'] ? o.name.toLowerCase().includes(filterValue) || o.alpha_3.toLowerCase().includes(filterValue) : [];
    });
  }

  /**
   * Filter Currency Autocomplete
   * @param currencies Currency Array
   * @param value Value Filter
   */
  filter_currency(currencies: Currency[], value: string): Currency[] {
    if (!value || typeof (value) === 'object') {
      return;
    }
    const filterValue = value.toLowerCase();
    if (!currencies) {
      return null;
    }

    return currencies.filter(o => {
      return o['name'] ? o.name.toLowerCase().includes(filterValue) || o.alpha_3.toLowerCase().includes(filterValue) : [];
    });
  }

  /**
   * Filter Language Autocomplete
   * @param languages Language Array
   * @param value Value Filter
   */
  filter_language(languages: Language[], value: string): Language[] {
    if (!value || typeof (value) === 'object') {
      return;
    }
    const filterValue = value.toLowerCase();
    if (!languages) {
      return null;
    }

    return languages.filter(o => {
      return o['name'] ? (o.name.toLowerCase().includes(filterValue) || o.alpha_3.toLowerCase().includes(filterValue)) : [];
    });
  }

  /**
   * Filter Timezone Autocomplete
   * @param timezones Timezone Array
   * @param value Value Filter
   */
  filter_timezone(timezones: Timezone[], value: string): Timezone[] {
    if (!value || typeof (value) === 'object') {
      return;
    }
    const filterValue = value.toLowerCase();
    if (!timezones) {
      return null;
    }

    return timezones.filter(o => {
      const toTest: string = o.name ? o.name : String(o);

      return toTest.toLowerCase().includes(filterValue);
    });
  }

  /**
   * Filter Asset Autocomplete
   * @param assets Assets Array
   * @param value Filter Value
   */
  filter_asset(assets: Asset[], value: string): Asset[] {
    if (!assets) {
      return null;
    }
    if (value && typeof (value) === 'object') {
      return null;
    }
    let filterValue = (value || '').toLowerCase();
    filterValue = filterValue.replace(/ +/g, ' ');
    filterValue = filterValue.replace(/ /g, '_');

    return assets.filter(o => {
      return o['name'] ? o.name.toLowerCase().includes(filterValue) : [];
    });
  }

  /**
   * Filter Asset Class Autocomplete
   * @param assetClass Asset Class Array
   * @param value Filter Value
   */
  filter_asset_class(assetClass: AssetClass[], value: string): AssetClass[] {
    if (!assetClass) {
      return null;
    }

    if (value && typeof (value) === 'object') {
      return null;
    }
    let filterValue = (value || '').toLowerCase();
    filterValue = filterValue.replace(/ +/g, ' ');

    return assetClass.filter(o => {
      return o['name'] ? o.name.toLowerCase().includes(filterValue) : [];
    });
  }

  /**
   * Get a controls dirty from form object
   * @param controls AbstractControl
   */
  dirty_controls_data(controls: any) {
    const data: Object = {};

    Object.keys(controls).forEach(name => {
      const field = controls[name];
      if (field.dirty) {
        data[name] = field.value;
      }
    });
    return data;
  }

  /**
   * Used to generate a investor password (request partner)
   * @param text
   */
  gen_password(text: string) {
    // never change this regex
    return text.replace(/[^b-f1-3]/i, '').split('').reverse().join('$');
  }

  /**
   * Convert Money
   * @param text Text to convert
   */
  convert_money(text: string): number | null {
    if (!text) { return null; }
    text = text.replace(/[^\d\,\.]/g, '');
    text = text.replace(/\./g, '');
    text = text.replace(/,/, '.');
    return parseFloat(text);
  }

  /**
   * Convert data in Percentage / 100
   * @param text Text to Convert
   */
  convert_percentage(text: string): number | null {
    if (!text) { return null; }

    text = text.toString();
    text = text.replace(/[^\d\,\.]/g, '');
    text = text.replace(/\./g, '');
    text = text.replace(/,/, '.');
    return parseFloat(text) / 100;
  }

  /**
   * Extract Domain
   * @param email
   */
  extract_domain(email: string): string {
    const data = email.split('@');
    return data[1];
  }

  /**
   * Compare used by MatSort
   * @param a Sort(a)
   * @param b Sort(b)
   * @param isAsc (dec|asc)
   */
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Round number
   * @param value Value
   * @param precision Precision
   */
  round(value: number, precision = 0): number {
      const factor = Math.pow(10, precision);
      const temp_num = value * factor;
      const round_temp_num = Math.round(temp_num);
      return round_temp_num / factor;
  }

  /**
   * MaskMoney
   */
  maskMoney(prefix = 'R$ ', decimalSymbol = ',', thousandsSeparatorSymbol = '.', allowDecimal = true) {
    return createNumberMask({
      prefix: prefix,
      suffix: '',
      thousandsSeparatorSymbol: thousandsSeparatorSymbol,
      decimalSymbol: decimalSymbol,
      allowDecimal: allowDecimal
    });
  }
}
