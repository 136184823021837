import { Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { Error404Component } from './error404/error404.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'investor',
    loadChildren: () => import('./investor/investor.module').then(m => m.InvestorModule)
    // loadChildren: './investor/investor.module#InvestorModule'
  },
  {
    path: 'partner',
    loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule)
    // loadChildren: './partner/partner.module#PartnerModule'
  },
  {
    path: 'manager',
    loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule)
    // loadChildren: './manager/manager.module#ManagerModule'
  },
  {
    path: 'roboadmin',
    loadChildren: () => import('./roboadmin/roboadmin.module').then(m => m.RoboadminModule)
    // loadChildren: './roboadmin/roboadmin.module#RoboadminModule'
  },
  {
    path: '**',
    component: Error404Component
  }
];
