import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig;

  constructor(private injector: Injector) { }

  /**
   * Load App Config
   */
  async loadAppConfig() {
    const http = this.injector.get(HttpClient);

    // no cache app config
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    return await http.get('assets/config/app.config.json?v=' + env.version, { headers: headers })
      .toPromise()
      .then(data => {
        this.appConfig = data;
      })
      .catch(err => {
        console.error(err);
      });
  }

  /**
   * Get Config
   */
  get config() {
    return this.appConfig;
  }
}
