import { Component, OnInit, ElementRef, HostBinding } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpdateService } from './shared/services/update.service';
import { BreadcrumbService } from './shared/services/breadcrumb.service';
import { ThemeService } from './shared/services/theme.service';

import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UtilService } from './shared/services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'robobanker-spa';
  appVersion: string = null;
  applyBg = false;

  trans_s = {
    'update_now': _('btn.UPDATE-NOW'),
    'update_available': _('text.Site-update-available'),
    'no_connection': _('text.No-Internet-connection'),
    'reconnected': _('text.Internet-reconnected')
  };

  constructor(private snackBar: MatSnackBar,
    private router: Router,
    private elRef: ElementRef,
    private updateService: UpdateService,
    private themeService: ThemeService,
    private breadService: BreadcrumbService,
    private translate: TranslateService,
    private utilService: UtilService,
    private route: ActivatedRoute,
  ) {
    for (const k of Object.keys(this.trans_s)) {
      this.translate.get(this.trans_s[k]).subscribe(m => this.trans_s[k] = m);
    }
  }

  ngOnInit() {
    this.themeService.applyBg.subscribe(v => this.applyBg = v);

    // query params
    this.route.queryParams.subscribe(qs => {
      if (qs['requestdebug'] === '1') {
        this.utilService.enableRequestDebug.next(true);
      }
    });

    // goto top page
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.breadService.breadcrumb.next(null);
        this.breadService.breadcrumbSubMenu.next(null);
        this.themeService.applyBg.emit(false);
        this.elRef.nativeElement.querySelector('.mat-drawer-content').scrollTop = 0;
      }
    });

    // conectivity
    window.addEventListener('offline', () => {
      this.snackBar.open(this.trans_s['no_connection'].toString(), 'OK', {
        duration: 0
      });
    });

    window.addEventListener('online', () => {
      this.snackBar.open(this.trans_s['reconnected'].toString(), 'OK', {
        duration: 5000
      });
    });

    // update app version
    this.updateService.appVersion.subscribe(v => {
      if (!this.appVersion) {
        this.appVersion = v;
        return;
      }
      if (this.appVersion !== v) {
        const snackRef = this.snackBar.open(this.trans_s['update_available'].toString(), this.trans_s['update_now'].toString(), {
          duration: 10000
        });

        snackRef.onAction().subscribe(() => {
          window.location.reload();
        });
      }
    });
  }

}
