import { Injectable } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { environment as env } from '../../../environments/environment';

import {
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

// INTERCEPT HTTP REQUEST
@Injectable()
export class HttpProvide implements HttpInterceptor {

  constructor(private loadingService: LoadingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req_clone;

    if (/\/i18n\/.*\.json$/.test(req.url)) {
      req_clone = req.clone({
        setHeaders: {
          'Cache-Control': 'must-revalidate, proxy-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        },
        url: req.url + '?v=' + env.version
      });
    } else {
      req_clone = req;
    }

    setTimeout(() => this.loadingService.isLoading.emit(true));

    return next.handle(req_clone)
      .pipe(
        finalize(() => {
          setTimeout(() => this.loadingService.isLoading.emit(false));
        })
      );
  }
}
