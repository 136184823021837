import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UtilService } from '../../shared/services/util.service';
import { StorageService as storage } from '../../shared/services/storage.service';
import { UpdateService } from '../../shared/services/update.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(private http: HttpClient,
    private updateService: UpdateService,
    private utilService: UtilService
  ) { }

  // Handle Error
  private handleError(error: Response | any): Promise<any> {
    // TODO LOG ERRORS
    return Promise.reject(error);
  }

  /**
  * Check App Update
  * @param res Response
  */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.updateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * Get JWT (system login)
   * @param systemKey System Key
   */
  getSystemJwt(systemKey: string): Promise<any> {
    const url = this.utilService.makeUri(['/system/jwt/system', { systemKey: systemKey }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.post(url, {}, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Get a Partner JWT
   * @param systemKey System Key
   * @param data Data Partner (uuid, ttl)
   */
  getPartnerJwt(systemKey: string, data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/system/jwt/partner', { systemKey: systemKey }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * List all Partners
   * @param page Page of request
   * @param rows Rows of request
   */
  listParnters(data: Object): Promise<any> {
    data['jwt'] = storage.getSystemJwt();

    const url = this.utilService.makeUri(['/system/partners', data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Create a new Partner
   * @param data Partner Data
   */
  createPartner(data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner', { jwt: storage.getSystemJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Get Partner Config
   * @param partner_uuid Partner UUID
   */
  getPartnerConfigPDI(partner_uuid: string) {
    const url = this.utilService.makeUri(['/system/partner', partner_uuid, 'config',
      { jwt: storage.getSystemJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Get Partner Config
   * @param partner_uuid Partner UUID
   */
  updatePartnerConfigPDI(data: Object, partner_uuid: string) {
    const url = this.utilService.makeUri(['/system/partner', partner_uuid, 'config',
      { jwt: storage.getSystemJwt() }]);

    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }
}
