import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

marker('label.great');
marker('label.two-years');
marker('label.range');

@Component({
    selector: 'app-dialog-select-range',
    templateUrl: './dialog-select-range.component.html' ,
    styleUrls: ['./dialog-select-range.component.css']
  })
  export class DialogSelectRangeComponent {

    constructor(public dialogRef: MatDialogRef<DialogSelectRangeComponent>) {

    }
    rangeForm = new FormGroup({
        beginControl: new FormControl(),
        endControl: new FormControl()
    });

    onNoClick(): void {
        this.dialogRef.close();
    }
  }
