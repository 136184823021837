import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UtilService } from '../../shared/services/util.service';
import { UpdateService } from '../../shared/services/update.service';

@Injectable({
  providedIn: 'root'
})
export class EnumService {

  constructor(private http: HttpClient,
    private updateService: UpdateService,
    private utilService: UtilService
  ) { }

  // Handle Error
  private handleError(error: Response | any): Promise<any> {
    // TODO LOG ERRORS
    return Promise.reject(error);
  }

  /**
  * Check App Update
  * @param res Response
  */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.updateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  // Common request
  common(url: string): Promise<any> {
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  // Sec Type
  sectypes(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/sectypes', { jwt: jwt }]);
    return this.common(url);
  }

  // Sub Sec Type
  sub_sectypes(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/sub-sectypes', { jwt: jwt }]);
    return this.common(url);
  }

  // Currency
  currencies(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/currencies', { jwt: jwt }]);
    return this.common(url);
  }

  // Country
  countries(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/countries', { jwt: jwt }]);
    return this.common(url);
  }

  // Language
  languages(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/languages', { jwt: jwt }]);
    return this.common(url);
  }

  // Timezones
  timezones(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/timezones', { jwt: jwt }]);
    return this.common(url);
  }

  // Goal Types
  goals(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/goal-types', { jwt: jwt }]);
    return this.common(url);
  }

  // Income Types
  incomes(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/income-types', { jwt: jwt }]);
    return this.common(url);
  }

  /**
   * Get Issuers options REQUIRES PARTNER JWT
   */
  issuers(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/issuers', { jwt: jwt }]);
    return this.common(url);
  }

  /**
   * Get Tenor options
   */
  tenor(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/tenor-types', { jwt: jwt }]);
    return this.common(url);
  }

  /**
   * Get Account Types
   */
  account_types(jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/enum/account-types', { jwt: jwt }]);
    return this.common(url);
  }
}
