import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UtilService } from '../../shared/services/util.service';
import { UpdateService } from '../../shared/services/update.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(private http: HttpClient,
    private updateService: UpdateService,
    private utilService: UtilService
  ) { }

  // Handle Error
  private handleError(error: Response | any): Promise<any> {
    // TODO LOG ERRORS
    return Promise.reject(error);
  }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.updateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * List Assets
   * @param data Data Query Assets (filters)
   * @param jwt JSON Web Token
   */
  listAssets(data: Object, jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/assets', { jwt: jwt }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * List Partner Assets
   * @param data Data Query Assets (filters)
   * @param jwt JSON Web Token
   */
  listPartnerAssets(data: Object, jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/partner/assets', { jwt: jwt }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Get Asset
   * @param data Data search
   * @param jwt JSON Web Token
   */
  getAsset(data: Object, jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/asset', { jwt: jwt }, data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Run Par - Performance Analysis Report
   * @param data Data PAR
   * @param jwt JSON Web Token
   */
  par(data: Object, jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/par', { jwt: jwt }]);

    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Run Par Historical Portfolio - Performance Analysis Historical Portfolio
   * @param data Data PAR
   * @param jwt JSON Web Token
   */
   parHistoricalPortfolio(data: Object, jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/par', 'historical-portfolio', { jwt: jwt }]);

    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Run Par Future - Performance Analysis Report Future
   * @param data Data PAR
   * @param jwt JSON Web Token
   */
   parFuture(data: Object, jwt: string): Promise<any> {
    const url = this.utilService.makeUri(['/par', 'future', { jwt: jwt }]);

    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }

  /**
   * Price History
   * @param uuid Asset UUID
   * @param jwt JSON Web Token
   * @param filter Search Filter
   */
  priceHistory(uuid: string, jwt: string, filter: Object): Promise<any> {
    const url = this.utilService.makeUri(['/asset', uuid, 'price-history', { jwt: jwt }, filter]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(this.handleError);
  }
}


