import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { UtilService } from '../../shared/services/util.service';
import { StorageService as storage } from '../../shared/services/storage.service';
import { UpdateService } from '../../shared/services/update.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavitemsService } from './navitems.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line:directive-class-suffix
export class PartnerService {
  @Output() partnerData: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient,
    private router: Router,
    private updateService: UpdateService,
    private snackBar: MatSnackBar,
    private navitemsService: NavitemsService,
    private utilService: UtilService
  ) { }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.updateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * Hadle Error
   * Intercept all request error
   * @param error Response Object
   */
  private handleError(error: Response | any, router: Router): Promise<any> {

    // 401 erros authorization
    if (error.status === 401) {
      const json = error.error;
      let msg: string;
      if ('errors' in json && 'jwt' in json['errors'] && json['errors']['jwt'].length > 0) {
        msg = json['errors']['jwt'][0];
      }

      // logout if expired token
      if (msg === 'expired token' || msg === 'jwt decode error' || msg === 'this jwt was revoked'
        || msg === 'this jwt is wrong' || msg === 'this jwt is wrong, invalid or missing') {
        this.snackBar.open('Sua sessão expirou. Entre novamente.', 'OK', {
          duration: 10000
        });
        this.navitemsService.routerItems.next(null);
        storage.deletePartnerJwt();
        router.navigate(['/partner/login']);
      }
    }
    return Promise.reject(error);
  }

  /**
   * Get JWT
   */
  getJwt(): string {
    return storage.getPartnerJwt();
  }

  /**
   * Login Partner
   * @param data Data Object Login
   */
  login(data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/auth']);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const postData = this.utilService.buildPostData(data);

    return this.http.post(url, postData, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get Partner Info
   */
  getInfo(): Promise<any> {
    const url = this.utilService.makeUri(['/partner', { jwt: storage.getPartnerJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update Partner
   * @param data Data Object Update
   */
  updatePartner(data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner', { jwt: storage.getPartnerJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const postData = this.utilService.buildPostData(data);

    return this.http.put(url, postData, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update Partner Password
   * @param data Data Object Password Update
   */
  updatePassword(partner_uuid: string, data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner', partner_uuid, 'password', { jwt: storage.getPartnerJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const postData = this.utilService.buildPostData(data);

    return this.http.put(url, postData, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Upload Photo
   * @param imageBlob Image Blob
   */
  uploadPhoto(imageBlob: any): Promise<any> {
    const url = this.utilService.makeUri(['/partner/upload-logo', { jwt: storage.getPartnerJwt() }]);
    const formData = new FormData();
    formData.append('file', imageBlob);
    return this.http.post(url, formData, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * List all Partner Investors
   * @param filter Filter Object Query
   */
  investors(filter?: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/investors', { jwt: storage.getPartnerJwt() }, filter]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * List all Partner Investors Accounts
   * @param filter Filter Object Query
   */
  investorsAccounts(filter?: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/investors/accounts', { jwt: storage.getPartnerJwt() }, filter]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * List all Partner Managers
   * @param filter Filter Object Query
   */
  managers(filter?: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/managers', { jwt: storage.getPartnerJwt() }, filter]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Associate manager to partner
   * @param manager_uuid Manager UUID
   */
  assocManager(manager_uuid: string): Promise<any> {
    const url = this.utilService.makeUri(['/partner/manager', manager_uuid, { jwt: storage.getPartnerJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.post(url, {}, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a New Partner ApiKey
   * @param data ApiKey Object Data
   */
  createApiKey(data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/apikey', { jwt: storage.getPartnerJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const postData = this.utilService.buildPostData(data);

    return this.http.post(url, postData, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update a Partner ApiKey
   * @param apiKeyID ApiKey ID
   * @param data ApiKey Object Data
   */
  updateApiKey(apiKeyID: number, data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/apikey', apiKeyID, { jwt: storage.getPartnerJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const postData = this.utilService.buildPostData(data);

    return this.http.put(url, postData, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get all Partner ApiKeys
   */
  getApiKeys(): Promise<any> {
    const url = this.utilService.makeUri(['/partner/apikeys', { jwt: storage.getPartnerJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Partner ApiKey
   * @param apiKeyID ApiKey ID
   */
  getApiKey(apiKeyID: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/apikey', apiKeyID, { jwt: storage.getPartnerJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Partner ApiKey
   * @param apikey_id Partner ApiKey ID
   */
  deleteApiKey(apikey_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/apikey', apikey_id, { jwt: storage.getPartnerJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get list Partner Assets (negociated)
   */
  getAssets(data?: Object, jwt?: string): Promise<any> {
    if (!data) { data = {}; }
    data['jwt'] = jwt || storage.getPartnerJwt();

    const url = this.utilService.makeUri(['/partner/assets', data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Partner Asset
   * @param asset_uuid Asset UUID
   */
  getAsset(data: Object, jwt?: string): Promise<any> {
    data['jwt'] = jwt || storage.getPartnerJwt();

    const url = this.utilService.makeUri(['/partner/asset', data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Parner x Asset (association)
   * @param asset_uuid Asset UUID
   * @param data Data Object Partner x Asset
   */
  createPartnerAsset(asset_uuid: string, data?: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset', asset_uuid, { jwt: storage.getPartnerJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const postData = this.utilService.buildPostData(data);

    return this.http.post(url, postData, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update a Parner x Asset (association)
   * @param asset_uuid Asset UUID
   * @param data Data Object Partner x Asset
   */
  updatePartnerAsset(asset_uuid: string, data?: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset', asset_uuid, { jwt: storage.getPartnerJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const postData = this.utilService.buildPostData(data);

    return this.http.put(url, postData, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Partner x Asset
   * @param asset_uuid Asset UUID
   */
  getPartnerAsset(asset_uuid: string): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset', asset_uuid, { jwt: storage.getPartnerJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Partner x Asset
   * @param asset_uuid Asset UUID
   */
  deletePartnerAsset(asset_uuid: string): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset', asset_uuid, { jwt: storage.getPartnerJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Partner Allocation Restriction
   * @param data Object Partner Allocation Restriction Data
   */
  createRestriction(data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/allocation-restriction', { jwt: storage.getPartnerJwt() }]);
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update a Partner Allocation Restriction
   * @param restriction_id Restriction ID
   * @param data Object Partner Allocation Restriction Data
   */
  updateRestriction(restriction_id: number, data: Object, jwt?: string): Promise<any> {
    const data_qs = {
      jwt: jwt || storage.getPartnerJwt()
    };

    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, data_qs]);
    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Load Parnter Restrictions
   * @param data QueryParams
   * @param jwt JWT
   */
  getRestrictions(data?: Object, jwt?: string): Promise<any> {
    if (!data) { data = {}; }
    data['jwt'] = jwt || storage.getPartnerJwt();

    const url = this.utilService.makeUri(['/partner/allocation-restrictions', data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Partner Allocation Restriction
   * @param restriction_id Partner Alloction Restriction ID
   */
  getRestriction(restriction_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, { jwt: storage.getPartnerJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Partner Allocation Restriction
   * @param restriction_id Partner Allocation Restriction ID
   */
  deleteRestriction(restriction_id: number, jwt?: string): Promise<any> {
    const data = {
      jwt: jwt || storage.getPartnerJwt()
    };

    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, data]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Partner Alloction Restriction Asset
   * @param data Object Partner Allocation Restriction Asset Data
   * @param restriction_id Partner Allocation Restriction ID
   */
  createRestrictionAsset(data: Object, restriction_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, 'asset', { jwt: storage.getPartnerJwt() }]);
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Partner Alloction Restriction Asset
   * @param data Object Partner Allocation Restriction Asset Data
   * @param restriction_id Partner Allocation Restriction ID
   * @param restriction_asset_id Partner Allocation Restriction Asset ID
   */
  updateRestrictionAsset(data: Object, restriction_id: number, restriction_asset_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, 'asset',
      restriction_asset_id, { jwt: storage.getPartnerJwt() }]);
    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get list Partner Restriction Assets
   * @param restriction_id Partner Allocation Restriction ID
   */
  getRestrictionAssets(restriction_id: number, jwt?: string): Promise<any> {
    const data = {
      jwt: jwt || storage.getPartnerJwt()
    };

    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, 'assets', data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Partner Restriction Asset
   * @param restriction_id Partner Allocation Restriction ID
   * @param restriction_asset_id Partner Allocation Restriction Asset ID
   */
  getRestrictionAsset(restriction_id: number, restriction_asset_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, 'asset',
      restriction_asset_id, { jwt: storage.getPartnerJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Partner Restriction Asset
   * @param restriction_id Partner Allocation Restriction ID
   * @param restriction_asset_id Partner Allocation Restriction Asset ID
   */
  deleteRestrictionAsset(restriction_id: number, restriction_asset_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, 'asset',
      restriction_asset_id, { jwt: storage.getPartnerJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Asset Classification
   * @param data Partner Asset Classification Data
   */
  createAssetClassification(data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset-classification', { jwt: storage.getPartnerJwt() }]);
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update a Asset Classification
   * @param data Partner Asset Classification Data
   * @param classification_id Partner Classification ID
   */
  updateAssetClassification(classification_id: number, data: Object): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset-classification', classification_id, { jwt: storage.getPartnerJwt() }]);
    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get Partner Asset Classifications
   */
  getAssetClassifications(jwt?: string): Promise<any> {
    const data = {
      jwt: jwt || storage.getPartnerJwt()
    };

    const url = this.utilService.makeUri(['/partner/asset-classifications', data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Partner Asset Classification
   * @param classification_id Classification ID
   */
  getAssetClassification(classification_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset-classification', classification_id, { jwt: storage.getPartnerJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Partner Asset Classification
   * @param classification_id Classification ID
   */
  deleteAssetClassification(classification_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset-classification', classification_id, { jwt: storage.getPartnerJwt() }]);
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create a Restriction Asset Class
   * @param data Restriction Asset Class Data
   * @param restriction_id Restriction ID
   */
  createRestrictionAssetClass(data: Object, restriction_id: number): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/allocation-restriction', restriction_id, 'asset-classification', { jwt: storage.getPartnerJwt() }]
    );
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update a Restriction Asset Class
   * @param data Restriction Asset Classe Data
   * @param restriction_id Restriction ID
   * @param restriction_asset_classification_id Restriction Asset Class ID
   */
  updateRestrictionAssetClass(data: Object, restriction_id: number, restriction_asset_classification_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/allocation-restriction',
      restriction_id, 'asset-classification',
      restriction_asset_classification_id, { jwt: storage.getPartnerJwt() }]
    );
    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get list Restriction Asset Classes
   * @param restriction_id Restriction ID
   */
  getRestrictionAssetClasses(restriction_id: number, jwt?: string): Promise<any> {
    const data = {
      jwt: jwt || storage.getPartnerJwt()
    };

    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id,
      'asset-classifications', data]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Restriction Asset Class
   * @param restriction_id Restriction ID
   * @param restriction_asset_classification_id Restriction Asset Classification ID
   */
  getRestrictionAssetClass(restriction_id: number, restriction_asset_classification_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, 'asset-classification',
      restriction_asset_classification_id, { jwt: storage.getPartnerJwt() }]
    );
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete a Restriction Asset Class
   * @param restriction_id Restriction ID
   * @param restriction_asset_classification_id Restriction Asset Classification ID
   */
  deleteRestrictionAssetClass(restriction_id: number, restriction_asset_classification_id: number): Promise<any> {
    const url = this.utilService.makeUri(['/partner/allocation-restriction', restriction_id, 'asset-classification',
      restriction_asset_classification_id, { jwt: storage.getPartnerJwt() }]
    );
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Asset Classification Enum
   */
  assetClassificationEnum(): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset/classification-enum', { jwt: storage.getPartnerJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Asset Exposure Enum
   */
   partnerAssetExposureEnum(): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset/factor-exposures-enum', { jwt: storage.getPartnerJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Asset RiskLevel Enum
   */
  assetRiskLevelEnum(): Promise<any> {
    const url = this.utilService.makeUri(['/partner/asset/risklevel-enum', { jwt: storage.getPartnerJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get Cron Jobs
   */
  getCronJobs(): Promise<any> {
    const url = this.utilService.makeUri(['/partner/cron-jobs', { jwt: storage.getPartnerJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get CronJobStatus
   * @param project_name Project Name
   * @param flow_name Flow Name
   */
  getCronJobStatus(project_name: string, flow_name: string): Promise<any> {
    const url = this.utilService.makeUri(['/partner/cron-job', project_name, flow_name, 'status', { jwt: storage.getPartnerJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get CronJobHistory
   * @param project_name Project Name
   * @param flow_name Flow Name
   */
  getCronJobHistory(project_name: string, flow_name: string): Promise<any> {
    const url = this.utilService.makeUri(['/partner/cron-job', project_name, flow_name, 'history', { jwt: storage.getPartnerJwt() }]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Execute Cron Job
   * @param project_name Project Name
   * @param flow_name Flow Name
   */
  executeCronJob(project_name: string, flow_name: string): Promise<any> {
    const url = this.utilService.makeUri(['/partner/cron-job', project_name, flow_name, 'execute', { jwt: storage.getPartnerJwt() }]);

    return this.http.post(url, {}, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Cron Job Execute
   * @param project_name Project Name
   * @param flow_name Flow Name
   * @param exec_id Execution ID
   */
  executeCronJobDetail(project_name: string, flow_name: string, exec_id: number): Promise<any> {
    const url = this.utilService.makeUri([
      '/partner/cron-job', project_name, flow_name, 'execution', exec_id, 'detail', { jwt: storage.getPartnerJwt() }
    ]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Cron Job Cancel
   * @param project_name Project Name
   * @param flow_name Flow Name
   * @param exec_id Execution ID
   */
  executeCronJobCancel(project_name: string, flow_name: string, exec_id: number): Promise<any> {
    const url = this.utilService.makeUri([
      '/partner/cron-job', project_name, flow_name, 'execution', exec_id, 'cancel', { jwt: storage.getPartnerJwt() }
    ]);

    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get Brokers
   */
  getBrokers(): Promise<any> {
    const url = this.utilService.makeUri([
      '/brokers', { jwt: storage.getPartnerJwt() }
    ]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get Venues
   */
  getVenues(): Promise<any> {
    const url = this.utilService.makeUri([
      '/venues', { jwt: storage.getPartnerJwt() }
    ]);

    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create Investor Groups
   * @param data Data Investor Groups
   */
  createInvestorGroups(data: Object): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/investor-group', { jwt: storage.getPartnerJwt() }]
    );
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update Investor Groups
   * @param investor_group_id Investor Group ID
   * @param data Investor Group Data
   */
  updateInvestorGroups(investor_group_id: number, data: Object): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/investor-group', investor_group_id, { jwt: storage.getPartnerJwt() }]
    );
    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a Investor Group
   * @param investor_group_id Investor Group ID
   */
  getInvestorGroup(investor_group_id: number): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/investor-group', investor_group_id, { jwt: storage.getPartnerJwt() }]
    );
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete Investor Group
   * @param investor_group_id
   */
  deleteInvestorGroup(investor_group_id: number): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/investor-group', investor_group_id, { jwt: storage.getPartnerJwt() }]
    );
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get Investor Groups
   */
  getInvestorGroups(filter?: Object): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/investor-groups', { jwt: storage.getPartnerJwt() }, filter]
    );
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Create PDI Setting
   * @param data Data PDI Setting
   */
  createPdiSetting(data: Object): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/pdi-setting', { jwt: storage.getPartnerJwt() }]
    );
    return this.http.post(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Update PDI Setting
   * @param pdi_setting_id PDI Setting ID
   * @param data Data PDI Setting
   */
  updatePdiSetting(pdi_setting_id: number, data: Object): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/pdi-setting', pdi_setting_id, { jwt: storage.getPartnerJwt() }]
    );
    return this.http.put(url, data, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get a PDI Setting
   * @param pdi_setting_id Investor Group ID
   */
  getPdiSetting(pdi_setting_id: number): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/pdi-setting', pdi_setting_id, { jwt: storage.getPartnerJwt() }]
    );
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Delete PDI Setting
   * @param pdi_setting_id
   */
  deletePdiSetting(pdi_setting_id: number): Promise<any> {
    const url = this.utilService.makeUri(
      ['/partner/pdi-setting', pdi_setting_id, { jwt: storage.getPartnerJwt() }]
    );
    return this.http.delete(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get PDI Settings
   */
  getPdiSettings(filter?: Object): Promise<any> {
    const url = this.utilService.makeUri(
      ['partner/pdi-settings', { jwt: storage.getPartnerJwt() }, filter]
    );
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

}
