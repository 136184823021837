import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Product } from '../navigate-and-discover.component';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.css']
})
export class SelectProductComponent implements OnInit {

  @Input() productControl = new FormControl();

  filteredOptions: Observable<Product[]>;
    options: Product[] = [
      {color: '#ff0000', name: 'ALASKA BLACK FIC FIA-BDR NÍVEL I', absolute_profitability: -45.47,
      relative_profitability: -380.25, volatility: 35.92, sharpe: null},
      {color: '#00ff00', name: 'TORK FIC FIA', absolute_profitability: 11.19, relative_profitability: 96, volatility: 35.92, sharpe: null},
      {color: '#ffff00', name: 'ITAVERÁ LONG BIASED FIC FIA', absolute_profitability: 24.35,
      relative_profitability: 203.65, volatility: 25.21, sharpe: null},
      {color: '#fca478', name: 'Itaú Personnalite K2 FIC Multimercado', is_wallet: true, wallet_value: 34},
      {color: '#fcff78', name: 'Monetus High Yield FI RF CP', is_wallet: true, wallet_value: 33},
      {color: '#fca4ff', name: 'Monetus FIC Multimercado CP', is_wallet: true, wallet_value: 33},

    ];

  constructor() { }

  ngOnInit(): void {
    this.filteredOptions = this.productControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.options.slice())
    );
  }

  private _filter(name: string): Product[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(product: Product): string {
    return product && product.name ? product.name : '';
  }

}
