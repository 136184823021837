<mat-form-field>
    <mat-label>{{'label.choose-product' | translate}}</mat-label>
    <input class="choose-product-input" type="text"
        [placeholder]="'label.choose-product' | translate"
        aria-label="Number"
        matInput
        [formControl]="productControl"
        [matAutocomplete]="auto">
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
    {{option.name}}
    </mat-option>
</mat-autocomplete>