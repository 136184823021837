import { Injectable, Output, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { UtilService } from '../../shared/services/util.service';
import { StorageService as storage } from '../../shared/services/storage.service';
import { UpdateService } from '../../shared/services/update.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavitemsService } from './navitems.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class PmsExtraModuleService {
  @Output() partnerData: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient,
    private router: Router,
    private updateService: UpdateService,
    private snackBar: MatSnackBar,
    private navitemsService: NavitemsService,
    private utilService: UtilService
  ) { }

  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.updateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  /**
   * Hadle Error
   * Intercept all request error
   * @param error Response Object
   */
  private handleError(error: Response | any, router: Router): Promise<any> {
    // 401 erros authorization
    if (error.status === 401) {
      const json = error.error;
      let msg: string;
      if ('errors' in json && 'jwt' in json['errors'] && json['errors']['jwt'].length > 0) {
        msg = json['errors']['jwt'][0];
      }

      // logout if expired token
      if (msg === 'expired token' || msg === 'jwt decode error' || msg === 'this jwt was revoked' || msg === 'this jwt is wrong'
        || msg === 'this jwt is wrong, invalid or missing') {
        this.snackBar.open('Sua sessão expirou. Entre novamente.', 'OK', {
          duration: 10000
        });
        this.navitemsService.routerItems.next(null);
        storage.deletePartnerJwt();
        router.navigate(['/partner/login']);
      }
    }

    return Promise.reject(error);
  }

  /**
   * Get all data Investors/account/goals/incomes
   */
  daycovalGetInvestors(filter?: Object): Promise<any> {
    const url = this.utilService.makeUri(['/module/daycoval/investors', { jwt: storage.getPartnerJwt() }, filter]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }

  /**
   * Get all Allocations
   * @param filter Filter Allocations
   */
  daycovalGetAllocations(filter?: Object) {
    const url = this.utilService.makeUri(['/module/daycoval/allocations', { jwt: storage.getPartnerJwt() }, filter]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.handleError(err, this.router));
  }
}
