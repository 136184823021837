import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { SidenaItems } from '../interfaces/sidena-items';
import { SidenavUserdata } from '../interfaces/sidenav-userdata';
import { BehaviorSubject } from 'rxjs';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class NavitemsService {
  @Output() routerItems: BehaviorSubject<SidenaItems[]> = new BehaviorSubject(null);
  @Output() userData: BehaviorSubject<SidenavUserdata> = new BehaviorSubject(null);

  constructor() { }
}
