import {SelectionModel} from '@angular/cdk/collections';
import { Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';

import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { DialogAddProductComponent } from './dialog-add-product/dialog-add-product.component';
import { FundPerformanceComponent } from './fund-performance/fund-performance.component';

marker('text.stocks-free');
marker('text.stocks-outshore');
marker('text.stocks-index');
marker('label.stocks');
marker('label.fixed-income');
marker('label.multimarket');
marker('label.exchange');
marker('label.real-state');
marker('text.fixed-income');
marker('text.multimarket');
marker('text.exchange');
marker('text.real-state-fond');




export interface Fund {
  fund: string;
  position: number;
  value: number;
  risk: number;
  type: string;
  cnpj: string;
}

export interface FundType {
  name: string;
  type: string;
}

export interface Product {
  name: string;
  absolute_profitability?: number;
  relative_profitability?: number;
  volatility?: number;
  sharpe?: number;
  is_wallet?: boolean;
  wallet_value?: number;
  cnpj?: string;
  manager?: string;
  patrimony?: number;
  numOfCotists?: number;
  color: string;
}

const PRODUCT_DATA: Product[] = [];

const FUND_TYPES: FundType[] = [
  {name: 'label.stocks', type: 'STOCK'},
  {name: 'label.fixed-income', type: 'FIXED_INCOME'},
  {name: 'label.multimarket', type: 'MULTIMARKET'},
  {name: 'label.exchange', type: 'EXCHANGE'},
  {name: 'label.real-state', type: 'REAL_STATE'},
];

const FUND_DATA_STOCKS: Fund[] = [
  {position: 1, fund: 'SKOPOS BRK FIC FIA', value: 52.19, risk: 4, type: 'STOCK', cnpj: '33.000.167/0001-01'},
  {position: 2, fund: 'ULTRA PERFORMANCE CTM FIA BDR NIVEL 1', value: 28.96, risk: 5, type: 'STOCK', cnpj: '33.000.167/0001-01'},
  {position: 3, fund: 'WESTERN ASSET FIA BDR NÍVEL I', value: 25.22, risk: 4, type: 'STOCK', cnpj: '33.000.167/0001-01'},
  {position: 4, fund: 'BB AÇOES GLOBAIS FIC FIA BDR NÍVEL I', value: 24.24, risk: 5, type: 'STOCK', cnpj: '33.000.167/0001-01'},
  {position: 5, fund: 'INTER + IBOVESPA ATIVO FIA', value: 22.77, risk: 4, type: '', cnpj: '33.000.167/0001-01'},
  {position: 6, fund: 'FIA CAIXA INSTITUCIONAL BDR NÍVEL I', value: 22.38, risk: 5, type: 'STOCK_INDEX', cnpj: '33.000.167/0001-01'},
  {position: 7, fund: 'FIA CAIXA BDR NÍVEL I', value: 21.80, risk: 2, type: 'STOCK', cnpj: '33.000.167/0001-01'},
  {position: 8, fund: 'BRADESCO FIA BDR NÍVEL I', value: 20.83, risk: 4, type: 'STOCK', cnpj: '33.000.167/0001-01'},
  {position: 9, fund: 'BRADESCO FIC FIA BDR NÍVEL I', value: 20.21, risk: 3, type: 'STOCK', cnpj: '33.000.167/0001-01'},
  {position: 10, fund: 'ITAÚ US TECH FIC FIA IE', value: 17.21, risk: 4, type: 'STOCK_OUTSHORE', cnpj: '33.000.167/0001-01'},
];

const FUND_DATA_FIXED_INCOME: Fund[] = [
  {position: 1, fund: 'Tesouro pré-fixado 20.19%', value: 20.19, risk: 1, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
  {position: 2, fund: 'Tesouro IPCA + 14%', value: 17.96, risk: 1, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
  {position: 3, fund: 'Tesouro IPCA + 13%', value: 16.96, risk: 4, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
  {position: 4, fund: 'Tesouro IPCA + 12%', value: 15.96, risk: 2, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
  {position: 5, fund: 'Tesouro IPCA + 11%', value: 14.96, risk: 4, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
  {position: 6, fund: 'Tesouro IPCA + 10%', value: 13.96, risk: 1, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
  {position: 7, fund: 'Tesouro IPCA + 9%', value: 12.96, risk: 2, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
  {position: 8, fund: 'Tesouro IPCA + 8%', value: 11.96, risk: 2, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
  {position: 9, fund: 'Tesouro IPCA + 7%', value: 10.96, risk: 3, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
  {position: 10, fund: 'Tesouro IPCA + 6%', value: 9.96, risk: 1, type: 'FIXED_INCOME', cnpj: '33.000.167/0001-01'},
];


const FUND_DATA_MULTIMARKET: Fund[] = [
  {position: 1, fund: 'SKOPOS M', value: 41.19, risk: 4, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
  {position: 2, fund: 'ULTRA PERFORMANCE CTM M', value: 33.96, risk: 5, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
  {position: 3, fund: 'WESTERN ASSET FIA M', value: 25.22, risk: 4, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
  {position: 4, fund: 'BB AÇOES GLOBAIS M', value: 24.24, risk: 5, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
  {position: 5, fund: 'INTER + IBOVESPA M', value: 22.77, risk: 4, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
  {position: 6, fund: 'FIA CAIXA M', value: 28.38, risk: 5, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
  {position: 7, fund: 'FIA CAIXA M I', value: 21.80, risk: 2, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
  {position: 8, fund: 'BRADESCO M', value: 20.83, risk: 4, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
  {position: 9, fund: 'BRADESCO M I', value: 20.21, risk: 3, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
  {position: 10, fund: 'ITAÚ US M', value: 17.21, risk: 4, type: 'MULTIMARKET', cnpj: '33.000.167/0001-01'},
];

const FUND_DATA_EXCHANGE: Fund[] = [
  {position: 1, fund: 'ITAÚ CAMBIAL MASTER FI', value: 15.19, risk: 4, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
  {position: 2, fund: 'BRADESCO FIC FI CAMBIAL TNA', value: 12.96, risk: 3, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
  {position: 3, fund: 'BTG PACTUAL DIGITAL DOLAR FI CAMBIAL', value: 10.22, risk: 4, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
  {position: 4, fund: 'BTG PACTUAL DIGITAL EURO FI CAMBIAL', value: 8.24, risk: 5, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
  {position: 5, fund: 'BTG PACTUAL DIGITAL FI CAMBIAL', value: 7.77, risk: 4, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
  {position: 6, fund: 'FIA CAIXA INSTITUCIONAL BDR NÍVEL I', value: 6.38, risk: 5, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
  {position: 7, fund: 'BV DOLAR I', value: 5.80, risk: 2, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
  {position: 8, fund: 'BV EURO I', value: 4.83, risk: 4, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
  {position: 9, fund: 'BRADESCO DOLAR I', value: 3.21, risk: 3, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
  {position: 10, fund: 'BRADESCO EURO i', value: 2.21, risk: 4, type: 'EXCHANGE', cnpj: '33.000.167/0001-01'},
];

const FUND_DATA_REAL_STATE: Fund[] = [
  {position: 1, fund: 'BCFF11', value: 32.19, risk: 3, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
  {position: 2, fund: 'GGRC11', value: 28.96, risk: 3, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
  {position: 3, fund: 'JSRE11', value: 25.22, risk: 2, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
  {position: 4, fund: 'KNCR11', value: 24.24, risk: 4, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
  {position: 5, fund: 'KNRI11', value: 22.77, risk: 4, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
  {position: 6, fund: 'MALL11', value: 22.38, risk: 5, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
  {position: 7, fund: 'RBRF11', value: 21.80, risk: 2, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
  {position: 8, fund: 'XPLG11', value: 20.83, risk: 3, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
  {position: 9, fund: 'XPML11', value: 20.21, risk: 3, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
  {position: 10, fund: 'RCRB11', value: 17.21, risk: 4, type: 'REAL_STATE', cnpj: '33.000.167/0001-01'},
];

@Component({
  selector: 'app-navigate-and-discover',
  templateUrl: './navigate-and-discover.component.html',
  styleUrls: ['./navigate-and-discover.component.css']
})
export class NavigateAndDiscoverComponent implements OnInit {
  displayedColumns: string[] = ['fund', 'type', 'risk', 'value', 'select'];
  dataSource = new MatTableDataSource<Fund>();
  selection = new SelectionModel<Fund>(true, []);
  fundTypes: FundType[] = FUND_TYPES;
  fundType = 'STOCK';


  products: Product[] = [];
  benchmark: Product;

  @ViewChild('compareChart') compareChart: FundPerformanceComponent;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Fund>(FUND_DATA_STOCKS);
    this.selection = new SelectionModel<Fund>(true, []);
    this.products = PRODUCT_DATA;

    this.benchmark = {color: '#cccccc', name: 'CDI', absolute_profitability: 11.96,
    relative_profitability: 100.00, volatility: 0.05, sharpe: null};
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Fund): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  changeFundType(event) {
    this.selection.clear();
    switch (this.fundType) {
      case 'STOCK': this.dataSource.data = FUND_DATA_STOCKS; break;
      case 'FIXED_INCOME': this.dataSource.data = FUND_DATA_FIXED_INCOME; break;
      case 'MULTIMARKET': this.dataSource.data = FUND_DATA_MULTIMARKET; break;
      case 'EXCHANGE': this.dataSource.data = FUND_DATA_EXCHANGE; break;
      case 'REAL_STATE': this.dataSource.data = FUND_DATA_REAL_STATE; break;
    }
  }

  benchmarkChange(event) {
    this.benchmark = event;
  }

  addProduct(prod: Product) {
    if (prod) {
      this.products.push(prod);
      this.compareChart.addSeriesToCompareChart();
      this.compareChart.updateChart();
    }
  }

  removeProduct(index) {
    this.products.splice(index, 1);
    this.compareChart.addSeriesToCompareChart();
    this.compareChart.updateChart();
  }

  openAddProductDialog() {
    const dialogRef = this.dialog.open(DialogAddProductComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.product) {
        result.product.color = result.color;
        this.addProduct(result.product);
      }
    });
  }
}

