import { Injectable, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter(false);

  constructor() { }
}
